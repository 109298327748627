import React, { useEffect } from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_SALESPERSON_PROFILE } from '../../../queries';
import './SalespersonProfile.module.css';
import AssignedAccountsGrid from './AssignedAccountsGrid';
import SalesPerformancePanel from './SalesPerformancePanel';

const InfoItem = ({ label, value }) => (
    <Box className="info-item">
        <Typography className="info-label">{label}:</Typography>
        <Typography className="info-value">{value}</Typography>
    </Box>
);

const Section = ({ title, children }) => (
    <Paper className="section">
        <Typography className="section-title">{title}</Typography>
        <Box className="section-content">
            {children}
        </Box>
    </Paper>
);

const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return 'N/A';
    if (amount === 0) return '$0';
    const absAmount = Math.abs(Math.round(amount)).toLocaleString();
    return amount < 0 ? `-$${absAmount}` : `$${absAmount}`;
};

const formatPercent = (value) => {
    if (value === null || value === undefined) return 'N/A';
    if (value === 0) return '0%';
    const absValue = Math.abs(value).toFixed(2);
    return value < 0 ? `-${absValue}%` : `${absValue}%`;
};


const SalespersonProfile = ({ selectedSalesperson }) => {
    const { data, loading, error } = useQuery(GET_SALESPERSON_PROFILE, {
        variables: { ccode: selectedSalesperson?.cCode || '' },
        skip: !selectedSalesperson,
    });

    useEffect(() => {
        if (error) {
            console.error('Error fetching customer profile:', error);
        }
    }, [error]);

    if (loading) return <Typography sx={{ p: 3 }}>Loading...</Typography>;
    if (error) return <Typography sx={{ p: 3 }}>Error: {error.message}</Typography>;
    if (!data) return <Typography sx={{ p: 3 }}>No data available</Typography>;

    const profile = data.salespersonProfileByCcode;

    return (
        <Box className="customer-profile">

            <Section title="Scorecard">
                <Grid container spacing={2} className="nested-grid-container">
                    <Grid item xs={12}>
                        <SalesPerformancePanel ccode={selectedSalesperson?.cCode} />
                    </Grid>
                </Grid>
            </Section>

            <Grid container spacing={2} className="grid-container">
                {/* Top row */}

                {/* New ScoreCard row */}


                <Grid item xs={12} md={6} className="grid-item">
                    {/* Status Section */}
                    <Section title="Production">
                        <Grid container spacing={2} className="nested-grid-container">
                            <Grid item xs={12} sm={12} className="nested-grid-item">

                                <Box sx={{ overflowX: 'auto', width: '100%' }}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '8px' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}></th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>Quarter to Date</th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>6 months</th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>12 months</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', fontWeight: 500 }}>Avg Quotes per day</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>
                                                    {formatCurrency(profile.avgQuotesPerDayDollarsQr || 0)} ({Math.round(profile.avgQuotesPerDayLinesQr || 0)} lines)
                                                </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>
                                                    {formatCurrency(profile.avgQuotesPerDayDollars6m || 0)} ({Math.round(profile.avgQuotesPerDayLines6m || 0)} lines)
                                                </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>
                                                    {formatCurrency(profile.avgQuotesPerDayDollars12m || 0)} ({Math.round(profile.avgQuotesPerDayLines12m || 0)} lines)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', fontWeight: 500 }}>Avg Sales per day</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>
                                                    {formatCurrency(profile.avgSalesPerDayDollarsQr || 0)} ({Math.round(profile.avgSalesPerDayLinesQr || 0)} lines)
                                                </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>
                                                    {formatCurrency(profile.avgSalesPerDayDollars6m || 0)} ({Math.round(profile.avgSalesPerDayLines6m || 0)} lines)
                                                </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>
                                                    {formatCurrency(profile.avgSalesPerDayDollars12m || 0)} ({Math.round(profile.avgSalesPerDayLines12m || 0)} lines)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', fontWeight: 500 }}>Avg RFQs per day</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>
                                                    {Math.round(profile.avgRfqsPerDayLinesQr || 0)} lines
                                                </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>
                                                    {Math.round(profile.avgRfqsPerDayLines6m || 0)} lines
                                                </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>
                                                    {Math.round(profile.avgRfqsPerDayLines12m || 0)} lines
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Box>

                            </Grid>

                        </Grid>
                    </Section>
                </Grid>

                <Grid item xs={12} md={6} className="grid-item">
                    {/* Financials Section */}
                    <Section title="Assigned Accounts">
                        <Grid container spacing={2} className="nested-grid-container">
                            <Grid item xs={12} className="nested-grid-item">
                                <AssignedAccountsGrid ccode={selectedSalesperson.cCode} />
                            </Grid>
                        </Grid>
                    </Section>
                </Grid>

                {/*
                <Grid item xs={12} md={6} className="grid-item">
                 
                    <Section title="Performance">
                        <Grid container spacing={2} className="nested-grid-container">
                            <Grid item xs={12} className="nested-grid-item">
                                <InfoItem label="Year to date revenue" value={formatCurrency(profile.ytdRevenue)} />
                                <InfoItem label="Goal" value={formatCurrency(profile.goal)} />
                                <InfoItem label="Net new orders" value={formatCurrency(profile.netNewOrders)} />
                                <InfoItem label="Open Orders" value={formatCurrency(profile.openOrders)} />

                            </Grid>
                        </Grid>
                    </Section>
                </Grid>

                <Grid item xs={12} md={6} className="grid-item">

                    <Section title="Proficiency">



                        <Grid container spacing={2} className="nested-grid-container">
                            <Grid item xs={12} className="nested-grid-item" >
                                <InfoItem
                                    label="Quote to Close (30 days)"
                                    value={
                                        <>
                                            <div>% By Qty: {formatPercent(profile.quoteToClosePercentQty || 0)}</div>
                                            <div>% By Dollars: {formatPercent(profile.quoteToClosePercentDollars || 0)}</div>
                                        </>
                                    }
                                />
                                <InfoItem
                                    label="Sales vs. Sell Price (30 days)"
                                    value={
                                        <>
                                            <div>By Dollars: {formatCurrency(profile.salesVsSellPricePercentDollars || 0)}</div>
                                            <div>By Percent: {formatPercent(profile.salesVsSellPricePercentQty || 0)}</div>

                                        </>
                                    }
                                />

                               
                               

                            </Grid>
                        </Grid>


                    </Section>
                    
                </Grid>
                 */}
            </Grid>
        </Box>
    );
};

export default SalespersonProfile;