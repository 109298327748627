// themes/enterpriseGridOverrideTheme.js
import { gridClasses } from '@mui/x-data-grid';

export const enterpriseGridOverrideTheme = {
  // Force light theme and set CSS variables
  '& .MuiDataGrid-root': {
    border: 'none !important',
    backgroundColor: '#ffffff !important',
    color: '#000000 !important',
    // Override CSS variables to ensure light theme
    '--DataGrid-containerBackground': '#f5f5f5 !important',
    '--DataGrid-footerBackground': '#ffffff !important',
    '--DataGrid-headerBackground': '#f5f5f5 !important',
    '--DataGrid-rowBackground': '#ffffff !important',
    '--DataGrid-rowHoverBackground': '#f5f5f5 !important',
    '--DataGrid-selectedRowBackground': '#ffffff !important',
    '--unstable_DataGrid-radius': '0px !important', // Remove border radius

  },
'& .MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch': {
  color: '#000000 !important',
},

 
// MuiDataGrid-overlay
'& .MuiDataGrid-overlay': {
  backgroundColor: '#ffffff !important',
  color: '#000000 !important',
},


// MuiDataGrid-filler
'& .MuiDataGrid-filler': {
  backgroundColor: '#f5f5f5 !important',
  color: '#000000 !important',
},

// Add this to your enterpriseGridOverrideTheme
'& .MuiDataGrid-toolbarContainer': {
  backgroundColor: '#ffffff !important',
  '& .MuiButton-root': {
    color: '#000000 !important',
    '& .MuiSvgIcon-root': {
      color: '#000000 !important',
    },
  },
  // Add these styles for the search input
  '& .MuiInput-input': {
    color: '#000000 !important',
    '&::placeholder': {
      color: '#666666 !important',
      opacity: 1,
    },
  },
},

  // Toolbar
'& .MuiDataGrid-toolbarContainer': {
  backgroundColor: '#f5f5f5 !important',
  '& .MuiButton-root': {
    color: '#000000 !important',
    '& .MuiSvgIcon-root': {
      color: '#000000 !important',
    },
  },
},

  // Override top and bottom container rows separately
  '&.MuiDataGrid-root .MuiDataGrid-container--top [role=row]': {
    backgroundColor: '#f5f5f5 !important',
  },
  '&.MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]': {
    backgroundColor: '#ffffff !important',
  },
// To this correct selector
'& .MuiSvgIcon-root': {
  color: '#000000 !important',
},
// .css-13mcxxu-MuiSvgIcon-root 
  // Column Headers
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#f5f5f5 !important', // Light gray background
    borderBottom: '1px solid #e0e0e0 !important',
    color: '#000000 !important',
    fontSize: '0.85rem !important',
    fontWeight: 800,
    minHeight: '40px !important',
   // maxHeight: '40px !important',
    // Remove flex display
    // display: 'flex !important',
    // alignItems: 'center !important',

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: '600 !important',
      color: '#000000 !important',
      // Adjust padding for vertical alignment
  //    paddingTop: '8px !important',
//      paddingBottom: '8px !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'visible !important',
      color: '#e0e0e0 !important',
    },
    // Ensure sort icons are visible
    '& .MuiDataGrid-sortIcon': {
      color: '#666666 !important',
    },
    '& .MuiDataGrid-menuIcon': {
      color: '#666666 !important',
    },
  },

  // Rows
  '& .MuiDataGrid-row': {
    backgroundColor: '#ffffff',
    color: '#000000 !important',
    fontSize: '0.75rem !important',

  //  minHeight: '32px !important',

  //  maxHeight: '32px !important',
    // Remove flex display and alignment
    // display: 'flex !important', // Ensure flex display
    // alignItems: 'center !important', // Center content vertically
 //   paddingTop: '8px !important',
//    paddingBottom: '8px !important',

    '&:hover': {
      backgroundColor: '#f5f5f5 !important',
    },
    '&.Mui-selected': {
      backgroundColor: '#ffffff !important',
      '&:hover': {
        backgroundColor: '#f5f5f5 !important',
      },
    },
  },

  // Cells
  '& .MuiDataGrid-cell': {
    borderBottom: '1px solid #e0e0e0 !important',
   // padding: '8px !important', // Adjust padding for vertical alignment
    color: '#000000 !important',
    backgroundColor: '#ffffff !important',
    fontSize: '0.75rem !important',
    // Remove flex display and alignment
    // display: 'flex !important', // Ensure flex display
    // alignItems: 'center !important', // Center content vertically
    // Use padding for vertical alignment
 //   paddingTop: '8px !important',
   // paddingBottom: '8px !important',

    '&:focus': {
      outline: 'none !important',
    },
    '&:focus-within': {
      outline: 'none !important',
    },
    '& a': {
      color: '#0066cc !important',
      textDecoration: 'none !important',
      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
  },

  // Currency Cells
  '& .MuiDataGrid-cell[data-type="currency"]': {
    color: '#000000 !important',
    fontFamily: '"Roboto Mono", monospace !important',
    '&.negative': {
      color: '#ff0000 !important',
    },
  },

  // Footer Container
  '& .MuiDataGrid-footerContainer': {
    borderTop: '1px solid #e0e0e0 !important',
    backgroundColor: '#ffffff !important',
 //   minHeight: '35px !important',
  },

  // Pagination
  '& .MuiTablePagination-root': {
    color: '#000000 !important',
    fontSize: '0.75rem !important',
  },
  '& .MuiTablePagination-displayedRows': {
    fontSize: '0.75rem !important',
  },
  '& .MuiTablePagination-selectLabel': {
    fontSize: '0.75rem !important',
  },

  // Remove Focus Outlines
  '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
    '&:focus, &:focus-within': {
      outline: 'none !important',
    },
  },
};
