import React, { useState, useEffect, useMemo } from 'react';
import { Box, Tabs, Tab, Typography, Select, MenuItem, FormControl, Paper } from '@mui/material';

import { useSearchParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';


import '@fontsource/orbitron/700.css'; // For bold weight

import { Circle, CircleOutlined } from '@mui/icons-material';

import SalesDepartmentPerformancePanel from './SalesDepartmentPerformancePanel';

// Import your GraphQL query
import { GET_DAILY_SALESPERSON_SALES_PRODUCTION, GET_DAILY_SALESPERSON_PRICING_PRODUCTION, GET_ASSIGNED_ACCOUNTS } from '../../../queries'; // Adjust the path as needed



const SalesDepartmentScorecard = () => {




    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography sx={{
                fontFamily: 'Orbitron, sans-serif',

                color: '#FDB813',
                fontStyle: 'italic',
                fontSize: '1.5rem',
            }}
                component="h1" gutterBottom align="left">
                Sales Department Scorecard
            </Typography>
            <Box sx={{ mt: 2 }}>
                <SalesDepartmentPerformancePanel />
            </Box>
        </Paper>
    );
};


export default SalesDepartmentScorecard;
