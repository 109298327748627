import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from '@mui/material';
import GroupBox from '../common/GroupBox';
import { useQuery } from '@apollo/client';
import { GET_FEATURES, GET_ALL_QUERIES } from '../../PurchasingScorecardQueries';
import useAuthenticatedPost from '../../hooks/useAuthenticatedPost';
import config from '../../config';

const QueryCommanderHome = () => {
  const [selectedFeature, setSelectedFeature] = useState('');
  const [selectedQueryId, setSelectedQueryId] = useState('');
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [queryText, setQueryText] = useState(''); // new state for text box content
  const [saveStatus, setSaveStatus] = useState(null);
  const { post, isLoading: isSaving, error: postError } = useAuthenticatedPost();

  const { data: featuresData, loading: featuresLoading, error: featuresError } = useQuery(GET_FEATURES);
  const { data: queriesData, loading: queriesLoading, error: queriesError } = useQuery(GET_ALL_QUERIES);

  // When queries load, select the first query if none is selected yet.
  useEffect(() => {
    if (queriesData && queriesData.queries && queriesData.queries.length > 0 && !selectedQuery) {
      const initialQuery = queriesData.queries[0];
      setSelectedQuery(initialQuery);
      setSelectedQueryId(initialQuery.id);
      setQueryText(initialQuery.sql); // initialize text state
    }
  }, [queriesData, selectedQuery]);

  const handleFeatureChange = (event) => {
    setSelectedFeature(event.target.value);
    // Optionally filter queries based on the selected feature.
  };

  const handleQueryChange = (event) => {
    const queryId = event.target.value;
    setSelectedQueryId(queryId);
    const foundQuery = queriesData.queries.find((q) => q.id === queryId);
    setSelectedQuery(foundQuery);
    setQueryText(foundQuery.sql); // update text state on query change
  };

  const handleTextChange = (event) => {
    setQueryText(event.target.value);
  };

  const handleSave = async () => {
    if (!selectedQuery) return;
    try {
      // Use the current text box contents instead of the original SQL.
      const requestBody = JSON.stringify(queryText);
      const response = await post(
        `${config.apiBaseUrl}/queryCommander/saveQuery?queryName=${encodeURIComponent(selectedQuery.name)}`,
        requestBody,
        'application/json'
      );
      setSaveStatus('Query saved successfully');
    } catch (error) {
      setSaveStatus(`Error saving query: ${error.message}`);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
      <Typography variant="h1" component="h1" gutterBottom align="left">
        Query Commander
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          border: '1px solid black',
          boxSizing: 'border-box',
        }}
      >
        {/* Top box with Filters and Queries */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            boxSizing: 'border-box',
            border: '1px solid black',
            mb: 2,
          }}
        >
          <Box
            sx={{
              flex: 1,
              border: '1px solid black',
              textAlign: 'center',
              minHeight: '100px',
              p: 2,
            }}
          >
            <GroupBox label="Filters">
              {featuresLoading ? (
                <CircularProgress />
              ) : featuresError ? (
                <Alert severity="error">Error loading features</Alert>
              ) : (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="feature-select-label">Feature</InputLabel>
                  <Select
                    labelId="feature-select-label"
                    value={selectedFeature}
                    label="Feature"
                    onChange={handleFeatureChange}
                  >
                    {featuresData.featureNames.map((feature, idx) => (
                      <MenuItem key={idx} value={feature}>
                        {feature}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </GroupBox>
          </Box>
          <Box
            sx={{
              flex: 1,
              border: '1px solid black',
              textAlign: 'center',
              minHeight: '100px',
              p: 2,
            }}
          >
            <GroupBox label="Queries">
              {queriesLoading ? (
                <CircularProgress />
              ) : queriesError ? (
                <Alert severity="error">Error loading queries</Alert>
              ) : (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="query-select-label">Query</InputLabel>
                  <Select
                    labelId="query-select-label"
                    value={selectedQueryId}
                    label="Query"
                    onChange={handleQueryChange}
                  >
                    {queriesData.queries.map((query) => (
                      <MenuItem key={query.id} value={query.id}>
                        {query.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </GroupBox>
          </Box>
        </Box>
        {/* Details Section */}
        <GroupBox label="Details">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            {/* Row with About, Arguments, and Columns */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                boxSizing: 'border-box',
                mb: 2,
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid purple',
                  textAlign: 'center',
                  minHeight: '100px',
                  p: 2,
                }}
              >
                <GroupBox label="About">
                  {selectedQuery ? (
                    <>
                      <Typography variant="subtitle1">{selectedQuery.description}</Typography>
                      <Typography variant="body2">Created by: {selectedQuery.createdBy}</Typography>
                      <Typography variant="body2">
                        Created on: {new Date(selectedQuery.createdOn).toLocaleString()}
                      </Typography>
                    </>
                  ) : (
                    <Typography>No query selected</Typography>
                  )}
                </GroupBox>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid purple',
                  textAlign: 'center',
                  minHeight: '100px',
                  p: 2,
                }}
              >
                <GroupBox label="Arguments">
                  {selectedQuery ? (
                    <>
                      <Typography variant="body2">Query Type: {selectedQuery.queryType}</Typography>
                      <Typography variant="body2">Feature: {selectedQuery.featureName}</Typography>
                      <Typography variant="body2">Time Period: {selectedQuery.timePeriod}</Typography>
                      <Typography variant="body2">Department: {selectedQuery.department}</Typography>
                    </>
                  ) : (
                    <Typography>No query selected</Typography>
                  )}
                </GroupBox>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid purple',
                  textAlign: 'center',
                  minHeight: '100px',
                  p: 2,
                }}
              >
                <GroupBox label="Columns">
                  {selectedQuery ? (
                    <>
                      <Typography variant="body2">Version: {selectedQuery.version}</Typography>
                      <Typography variant="body2">Version Tag: {selectedQuery.versionTag}</Typography>
                      <Typography variant="body2">Status: {selectedQuery.status}</Typography>
                      <Typography variant="body2">Valid: {selectedQuery.isValid ? 'Yes' : 'No'}</Typography>
                    </>
                  ) : (
                    <Typography>No query selected</Typography>
                  )}
                </GroupBox>
              </Box>
            </Box>
            {/* Row with Query and Results */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                boxSizing: 'border-box',
                mb: 2,
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid pink',
                  textAlign: 'center',
                  minHeight: '150px',
                  p: 2,
                }}
              >
                <GroupBox label="Query">
                  {selectedQuery ? (
                    <>
                      <TextField
                        fullWidth
                        multiline
                        minRows={4}
                        value={queryText}
                        onChange={handleTextChange}
                        variant="outlined"
                        sx={{ mb: 2 }}
                      />
                      <Button variant="contained" onClick={handleSave} disabled={isSaving}>
                        {isSaving ? 'Saving...' : 'Save Query'}
                      </Button>
                      {saveStatus && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          {saveStatus}
                        </Typography>
                      )}
                      {postError && (
                        <Typography variant="body2" sx={{ mt: 1, color: 'error.main' }}>
                          {postError.message}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography>No query selected</Typography>
                  )}
                </GroupBox>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid pink',
                  textAlign: 'center',
                  minHeight: '150px',
                  p: 2,
                }}
              >
                <GroupBox label="Results">
                  <Typography variant="body2">
                    Results will be displayed here after executing the query.
                  </Typography>
                </GroupBox>
              </Box>
            </Box>
          </Box>
        </GroupBox>
      </Box>
    </Paper>
  );
};

export default QueryCommanderHome;
