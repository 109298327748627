// components/PurchasingDepartmentScorecardTabSet.jsx

import React, { useState, useEffect, useMemo } from 'react';
import { Box, Tabs, Tab, Typography, Link } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import CalendarMasterDetail from '../../../CalendarMasterDetail/CalendarMasterDetail';
import '@fontsource/orbitron/700.css';

import { GET_DEPARTMENT_CALENDAR_QUERY } from '../../../../PurchasingScorecardQueries.js';

import PurchasingDepartmentPerformancePanel from './PurchasingDepartmentPerformancePanel.js';
import RFQDayDetails from './calendar/RFQDayDetails.js';

import MembershipPopup from '../MembershipPopup.js';
import SQLEditorPopup from '../SQLEditorPopup.js';
import config from '../../../../config.js';
import PODayDetails from './calendar/PODayDetails.js';
import CallTrackerDetails from './calendar/CallTrackerDetails.js';
import PriceUpdateDetails from './calendar/PriceUpdateDetails.js';

const PurchasingDepartmentScorecardTabSet = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCalendarTab, setActiveCalendarTab] = useState(0);
  // State for technical pop-ups
  const [openMembership, setOpenMembership] = useState(false);
  const [openSummaryQuery, setOpenSummaryQuery] = useState(false);
  const [openDetailsQuery, setOpenDetailsQuery] = useState(false);

  const [searchParams] = useSearchParams();
  const defaultTab = searchParams.get('tab');

  // http://localhost:3000/supply-chain/purchasing/department/scorecard/rfq-day-details?date=1/1/2025

  // For department-level queries, we're using a fixed department code
  const departmentCode = "Purchasing";

  // Load department activity data
  const [loadDepartmentData, { data: departmentData, loading, error }] = useLazyQuery(
    GET_DEPARTMENT_CALENDAR_QUERY,
    {
      variables: { ccode: departmentCode },
    }
  );

  // Set the initial active tab based on the 'tab' parameter in the URL
  useEffect(() => {
    if (defaultTab) {
      const tabIndex = parseInt(defaultTab, 10);
      if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex <= 4) {
        setActiveTab(tabIndex);

        // Load data if the activity tab is selected by default
        if (tabIndex === 1) {
          loadDepartmentData();
        }
      }
    }
  }, [defaultTab, loadDepartmentData]);

  // KEY CHANGE: Add a useEffect to reload data when the tab is active
  // This ensures the data is fresh and triggers a re-render
  useEffect(() => {
    if (activeTab === 1) {
      loadDepartmentData();
    }
  }, [activeTab, loadDepartmentData]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // Always load the data when switching to the activity tab
    // Removed the !departmentData condition
    if (newValue === 1) {
      loadDepartmentData();
    }
  };

  // New activities configuration for department-wide data
  const departmentActivitiesConfig = useMemo(() => [
    {
      type: 'RFQs',
      color: '#1976d2',
      dataFields: {
        docCount: 'totalRFQDocCount',  // Use the aggregated RFQ count from your data
        lineCount: 'totalRFQLineCount',
        total: 'totalRFQDollars',
      },
      minimal: false,
      weeklyAverage: true,
      monthlyAverage: true,
      detailView: RFQDayDetails,

    },
    {
      type: 'POs',
      color: '#2D875D',
      dataFields: {
        docCount: 'poDocCount',
        lineCount: 'poLineCount',
        total: 'poDollars',
      },
      minimal: false,
      weeklyAverage: true,
      monthlyAverage: true,
      detailView: PODayDetails,
    },
    {
      type: 'Price Updates',
      color: '#A23E48',
      dataFields: {
        // Assuming sellPricesUpdated holds a count or indicator; adjust as needed.
        lineCount: 'sellPricesUpdated',

      },
      minimal: true,
      weeklyAverage: false,
      monthlyAverage: false,
      detailView: PriceUpdateDetails,
    },
    {
      type: 'Call Trackers',
      color: '#9C4EE5',
      dataFields: {
        lineCount: 'callTrackerDocCount',

      },
      minimal: true,
      weeklyAverage: false,
      monthlyAverage: false,
      detailView: CallTrackerDetails,
    },
  ], []);

  // Format the department data as needed for CalendarMasterDetail
  const formattedDepartmentData = useMemo(() => {
    if (!departmentData) return [];
    // Add a console log to verify data is being processed
    console.log("Processing department data for calendars:", departmentData);
    return departmentData.departmentCalendarActivity.map((item) => ({
      date: item.date,
      ...item, // Spread all fields so that CalendarMasterDetail can access them via the keys defined in departmentActivitiesConfig
    }));
  }, [departmentData]);

  // KEY CHANGE: Add a key prop that changes when data changes to force re-render
  const calendarKey = departmentData ? `calendar-${departmentData.departmentCalendarActivity.length}` : 'calendar-empty';

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>






      {/* Tabs */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="department activity tabs"
          TabIndicatorProps={{
            style: { backgroundColor: 'white' },
          }}
        >
          <Tab
            label="Scorecard"
            sx={{
              color: activeTab === 0 ? '#ffffff !important' : '#b3b3b3',
              fontWeight: activeTab === 0 ? 'bold' : 'normal',
            }}
          />
          <Tab
            label="RFQs, POs, Price Updates, Call Trackers"
            sx={{
              color: activeTab === 1 ? '#ffffff !important' : '#b3b3b3',
              fontWeight: activeTab === 1 ? 'bold' : 'normal',
            }}
          />
        </Tabs>
      </Box>

      {/* Tab Content */}
      <Box id="tab-box" sx={{ flexGrow: 1, overflow: 'hidden', p: 0, height: '100%' }}>
        {activeTab === 0 && <PurchasingDepartmentPerformancePanel />}
        {activeTab === 1 && (
          <>
            {/* Technical options: small links for debugging */}
            <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              <Link
                sx={{ color: 'gray' }}
                component="button"
                variant="caption"
                onClick={() => setOpenMembership(true)}
              >
                Who is Included?
              </Link>
              <Link
                sx={{ color: 'gray' }}
                component="button"
                variant="caption"
                onClick={() => setOpenSummaryQuery(true)}
              >
                Calendar Summary Query
              </Link>
             
            </Box>

            <CalendarMasterDetail
              key={calendarKey} // KEY CHANGE: Add key prop to force re-render
              businessKey={departmentCode}
              data={formattedDepartmentData}
              currentYear={new Date().getFullYear()}
              currentMonth={new Date().getMonth()}
              onMonthChange={(year, month) => {
                console.log(`Month changed to: ${month + 1}/${year}`);
                // KEY CHANGE: Reload data when month changes
                loadDepartmentData();
              }}
              activities={departmentActivitiesConfig}
            />
          </>
        )}
      </Box>

       {/* Pop-up dialogs */}
            <MembershipPopup open={openMembership} onClose={() => setOpenMembership(false)} />
            <SQLEditorPopup
              open={openSummaryQuery}
              onClose={() => setOpenSummaryQuery(false)}
              title="Summary Query"
              endpoint={`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/queries/calendar/calendar-summary-query`}
            />
        
    </Box>
  );
};

export default PurchasingDepartmentScorecardTabSet;