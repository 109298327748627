import React, { useMemo, useState } from 'react';
import {
  Typography,
  Paper,
  Box,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Card,
  CardContent,
  Link,
} from '@mui/material';
import { DataGrid, GridToolbarExport, GridToolbarContainer, GridToolbar } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../../../../hooks/useAuthenticatedData';
import config from '../../../../config';
import { enterpriseGridOverrideTheme } from '../../../../themes/EnterpriseGridTheme';
import SQLEditorPopup from '../SQLEditorPopup';
import { set } from 'date-fns';
import MembershipPopup from '../MembershipPopup';


function PurchasingDepartmentNewVendorPODetails() {
  const location = useLocation();
  const navigate = useNavigate();

  // State for pop-up dialogs for query links
  const [openNewVendorSummary, setOpenNewVendorSummary] = useState(false);
  const [openNewVendorPODetails, setOpenNewVendorPODetails] = useState(false);

  const [openMembership, setOpenMembership] = useState(false);


  // Get the period parameter from the query string; default to 'MTD'
  const searchParams = new URLSearchParams(location.search);
  const period = searchParams.get('period')?.toUpperCase() || 'MTD';

  // Construct the REST endpoint URL using the provided config and period
  const url = `${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/new-vendor-po-details?period=${period}`;

  const { data, isLoading, error } = useAuthenticatedData(url);

  // When a tab is clicked, update the query param in the URL.
  const handleTabChange = (event, newPeriod) => {
    navigate({
      pathname: location.pathname,
      search: `?period=${newPeriod}`,
    });
  };

  // Process the REST data into rows for the DataGrid.
  const rows = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];
    return data.map((item) => ({
      ...item,
    }));
  }, [data]);

  // Generate export filename: e.g. Purchasing-MTD-new-vendor-po-details
  const generateExportFileName = () => {
    return `Purchasing-${period}-new-vendor-po-details`;
  };

  // Define DataGrid columns based on the sample data structure.
  const columns = useMemo(
    () => [
      { field: 'name', headerName: 'Vendor', width: 150 },
      { field: 'fullName', headerName: 'Buyer', width: 150 },
      { field: 'docNo', headerName: 'Doc No', width: 120 },
      { field: 'userLine', headerName: 'User Line', width: 80 },
      {
        field: 'addedOnDate',
        headerName: 'Added On',
        width: 150,
        type: 'dateTime',
        valueFormatter: (params) => (params ? new Date(params).toLocaleDateString() : ''),
      },
      { field: 'partNumber', headerName: 'Part Number', width: 150 },
      {
        field: 'spend',
        headerName: 'Spend',
        width: 120,
        type: 'number',
        valueFormatter: (params) =>
          params != null ? `$${Number(params).toLocaleString()}` : '',
      },
    ],
    []
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{ fileName: generateExportFileName() }}
          printOptions={{ fileName: generateExportFileName() }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
      <Typography variant="h1" component="h1" gutterBottom align="left">
        Purchasing Scorecard - New Vendor PO Details
      </Typography>

       {/* About the Data and Query Links Block */}
       <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
        {/* Technical options: small links for debugging - Now Above */}
        <Box sx={{ mb: 1, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Link
            sx={{ color: 'gray' }}
            component="button"
            variant="caption"
            onClick={() => setOpenMembership(true)}
          >
            Who is Included?
          </Link>
          <Link
            sx={{ color: 'gray' }}
            component="button"
            variant="caption"
            onClick={() => setOpenNewVendorSummary(true)}
          >
            Summary Query
          </Link>
          <Link
            sx={{ color: 'gray' }}
            component="button"
            variant="caption"
            onClick={() => setOpenNewVendorPODetails(true)}
          >
            Details Query
          </Link>
        </Box>


        <Box sx={{ flex: 1, minWidth: 300 }}>
          <Card
            sx={{
              background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
              border: '1px solid #424242',
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
              borderRadius: '12px',
            }}
          >
            <CardContent sx={{ p: 1 }}>
              <Typography
                variant="h6"
                sx={{
                  color: '#ffffff',
                  fontWeight: 500,
                  fontSize: '.9rem',
                  mb: 0.5,
                }}
              >
                About the Data
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: '#b0b0b0',
                  fontSize: '0.75rem',
                }}
              >
                These are vendors the purchasing team has bought from (by creating a purchase order) 
                that we didn't buy from last year. When we buy from one of these vendors, we count them as 
                "new". These are the purchase orders for those vendors.
              </Typography>
            </CardContent>
          </Card>
        </Box>

      </Box>

      <Box sx={{ mt: 2 }}>
        {/* Tabs for switching periods */}
        <Tabs
          value={period}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{ mb: 2 }}
        >
          <Tab
            sx={{
              color: period === 'MTD' ? '#ffffff !important' : '#b3b3b3',
              fontWeight: period === 'MTD' ? 'bold' : 'normal',
            }}
            label="Month-to-Date"
            value="MTD"
          />
          <Tab
            sx={{
              color: period === 'QTD' ? '#ffffff !important' : '#b3b3b3',
              fontWeight: period === 'QTD' ? 'bold' : 'normal',
            }}
            label="Quarter-to-Date"
            value="QTD"
          />
          <Tab
            sx={{
              color: period === 'YTD' ? '#ffffff !important' : '#b3b3b3',
              fontWeight: period === 'YTD' ? 'bold' : 'normal',
            }}
            label="Year-to-Date"
            value="YTD"
          />
        </Tabs>
      </Box>

      <Paper elevation={3} sx={{ height: 400, width: '100%', p: 2 }}>
        <Box sx={{ height: '100%' }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
              <Typography sx={{ ml: 2 }}>Loading...</Typography>
            </Box>
          ) : error ? (
            <Alert severity="error">Error: {error.message}</Alert>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 25]}
              disableSelectionOnClick
              autoHeight={false}
              headerHeight={40}
              sx={enterpriseGridOverrideTheme}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  csvOptions: { fileName: generateExportFileName() },
                  printOptions: { fileName: generateExportFileName() },
                },
              }}
            />
          )}
        </Box>
      </Paper>

      {/* Pop-up dialogs for query links */}
      <MembershipPopup open={openMembership} onClose={() => setOpenMembership(false)} />
      
      <SQLEditorPopup
        open={openNewVendorSummary}
        onClose={() => setOpenNewVendorSummary(false)}
        title="New Vendor Summary Query"
        endpoint={`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/queries/new-vendor-summary-query`}
      />
      <SQLEditorPopup
        open={openNewVendorPODetails}
        onClose={() => setOpenNewVendorPODetails(false)}
        title="New Vendor PO Details Query"
        endpoint={`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/queries/new-vendor-details-po-query`}
      />
    </Paper>
  );
}

export default PurchasingDepartmentNewVendorPODetails;
