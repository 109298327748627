// PriceUpdateDetails.js
import React, { useMemo, useState } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Alert,
    Paper,
    Link,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useAuthenticatedData from '../../../../../hooks/useAuthenticatedData';
import config from '../../../../../config';
import { enterpriseGridOverrideTheme } from '../../../../../themes/EnterpriseGridTheme';
import {  useSearchParams } from 'react-router-dom';

import MembershipPopup from '../../MembershipPopup.js';
import SQLEditorPopup from '../../SQLEditorPopup.js';

const PriceUpdateDetails = ({ date: propDate }) => {

    const [openMembership, setOpenMembership] = useState(false);
    const [openSummaryQuery, setOpenSummaryQuery] = useState(false);
    const [openDetailsQuery, setOpenDetailsQuery] = useState(false);

    const searchParams = useSearchParams();

    // Always compute the effective date using useMemo.
    const computedDate = useMemo(() => {
        if (propDate) return propDate;
        const dateString = searchParams.get('date');
        return dateString ? new Date(dateString) : null;
    }, [propDate, searchParams]);

    // Always compute a formatted date string. If computedDate is null, default to an empty string.
    const formattedDate = computedDate
        ? computedDate.toISOString().split('T')[0]
        : '';

    // Always build an endpoint string. If no valid date is available, pass an empty string.
    const endpoint = formattedDate
        ? `${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/get-price-updates-for-day?date=${formattedDate}`
        : '';


    // Fetch price update details.
    const { data, isLoading, error } = useAuthenticatedData(endpoint);

    // Prepare rows and define columns.
    const { rows, columns } = useMemo(() => {
        if (!data) return { rows: [], columns: [] };

        const rows = data.map((record, index) => ({
            id: record.id || index,
            ...record,
        }));

        const columns = [
            {
                field: 'fullName',
                headerName: 'Full Name',
                width: 200,
            },
            {
                field: 'partNumber',
                headerName: 'Part Number',
                width: 150,
            },
            {
                field: 'description',
                headerName: 'Description',
                width: 250,
            },
            {
                field: 'priceMessage',
                headerName: 'Price Message',
                width: 500,
            },
            {
                field: 'type',
                headerName: 'Type',
                width: 200,
            },

        ];

        return { rows, columns };
    }, [data]);

    if (isLoading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error.message}</Alert>;

    return (
        <Paper elevation={3} sx={{ p: 2, maxWidth: '100%', mt: 2 }}>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6" component="h2">
                    Price Update Details for Day
                </Typography>
            </Box>

            {/* Technical options: small links for debugging */}
            <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>

                <Link
                    sx={{ color: 'gray' }}
                    component="button"
                    variant="caption"
                    onClick={() => setOpenDetailsQuery(true)}
                >
                    Details Query
                </Link>
            </Box>

            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    density="compact"
                    rows={rows}
                    columns={columns}
                    disableSelectionOnClick
                    sx={enterpriseGridOverrideTheme}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            csvOptions: { fileName: 'price-updates-export' },
                            printOptions: { fileName: 'price-updates-export' },
                        },
                    }}
                />
            </Box>

            <SQLEditorPopup
                open={openDetailsQuery}
                onClose={() => setOpenDetailsQuery(false)}
                title="Details Query"
                endpoint={`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/queries/calendar/calendar-pricing-events-details-query`}
            />
        </Paper>
    );
};

export default PriceUpdateDetails;
