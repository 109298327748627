// CustomerTabSet.js
import React, { useState, useEffect, createContext, useContext, useRef, useMemo } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import CustomerSelect from './CustomerSelect';
import Production from './Production';
import Performance from './Performance';
import { gql, useQuery } from '@apollo/client';
import { GET_PRODUCTION_DAILY } from '../../queries'; // Adjust the import path as needed
import CustomerLandingPage from './CustomerLandingPage';
import CustomerProfile from './CustomerProfile';
import { useSearchParams } from 'react-router-dom';
import CalendarMasterDetail from '../CalendarMasterDetail/CalendarMasterDetail';
import CustomerQuoteDetail from './CustomerQuoteDetail';
import CustomerSalesDetail from './CustomerSalesDetail';
import CustomerCallDetail from './CustomerCallDetail';

import './CustomerTabSet.module.css';
import Proficiency from './Proficiency';


// Create a context for sharing customer data
const CustomerDataContext = createContext();

const CustomerTabSet = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const prevCustomerRef = useRef();
  const [customerData, setCustomerData] = useState(null);
  const initialLoadRef = useRef(true); // To ensure initial selection is handled only once


  const [searchParams, setSearchParams] = useSearchParams();
  const defaultAccountNumber = searchParams.get('acctno');
  const defaultTab = searchParams.get('tab');




  // Define activities configuration for Production
  const activitiesConfig = useMemo(() => [
    {
      type: 'QUOTES',
      color: '#1976d2',
      dataFields: {
        docCount: 'quoteDocCount',
        lineCount: 'quoteLineCount',
        total: 'quoteTotal',
      },
      minimal: false,
      weeklyAverage: true,
      monthlyAverage: true,

      detailView: CustomerQuoteDetail,


    },
    {
      type: 'SALES',
      color: '#2D875D',
      dataFields: {
        docCount: 'salesOrderDocCount',
        lineCount: 'salesOrderLineCount',
        total: 'salesOrderTotal',
      },
      minimal: false,
      weeklyAverage: true,
      monthlyAverage: true,
      detailView: CustomerSalesDetail,

    },
    {
      type: 'CALLS',
      color: '#9C4EE5',
      dataFields: {
        docCount: 'callDocCount',
        lineCount: 'callCount',
        total: 'callTotal',
      },
      minimal: true,
      weeklyAverage: false,
      monthlyAverage: false,
      detailView: CustomerCallDetail,

    },
  ], []);


  // Set the initial active tab based on the 'tab' parameter in the URL
  useEffect(() => {
    if (defaultTab) {
      const tabIndex = parseInt(defaultTab, 10); // Convert the 'tab' parameter to an integer
      if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex <= 4) {
        setActiveTab(tabIndex);
      }
    }
  }, [defaultTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    // Optionally, remove 'acctno' from URL if present
    if (searchParams.has('acctno')) {
      searchParams.delete('acctno');
      setSearchParams(searchParams);
    }
  };

  // Execute the query

  const { data, loading, error, refetch } = useQuery(GET_PRODUCTION_DAILY, {
    variables: { acctNo: selectedCustomer?.acctNo || '' },
    skip: !selectedCustomer, // Use 'skip' instead of 'pause'
    fetchPolicy: 'network-only', // Adjust as needed
  });



  useEffect(() => {
    if (selectedCustomer) {
      refetch(); // Re-execute the query when a new customer is selected
    }
  }, [selectedCustomer, refetch]);

  useEffect(() => {
    if (data && !loading) {
      setCustomerData(data);
    }
  }, [data, loading]);

  // Format the production data as needed for CalendarMasterDetail
  const formattedProductionData = useMemo(() => {
    if (!data) return [];
    return data.dailyProductionForCustomer.map((item) => ({
      date: item.date,
      ...item, // Spread all fields dynamically
    }));
  }, [data]);


  return (
    <CustomerDataContext.Provider value={{ customerData, loading, error }}>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Customer Title and Select */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // padding: 2,
          paddingTop: '16px',
          paddingLeft: '16px',
          borderBottom: '1px solid #e0e0e0',
        }}>
          <Typography sx={{
            fontFamily: 'Orbitron, sans-serif',

            color: '#FDB813',
            fontStyle: 'italic',
            fontSize: '1.5rem',
          }}  >
            {selectedCustomer ? `${selectedCustomer.name}` : 'No Customer Selected'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'gray' }}>
            {selectedCustomer ? `Assigned Salesperson: ${selectedCustomer.assignedSalesperson}` : ''}
          </Typography>
          <CustomerSelect onCustomerSelect={handleCustomerSelect} defaultAccountNumber={defaultAccountNumber} />
        </Box>

        {/* Tabs */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between', borderBottom: 1, borderColor: 'divider'
        }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="customer tabs"
            TabIndicatorProps={{
              style: { backgroundColor: 'white' }, // This maintains the indicator's white color for the active tab
            }}
          >
            <Tab
              label="Landing Page"
              sx={{
                color: activeTab === 0 ? '#ffffff !important' : '#b3b3b3',
                fontWeight: activeTab === 0 ? 'bold' : 'normal',
              }}
            />
            <Tab
              label="Profile"
              sx={{
                color: activeTab === 1 ? '#ffffff !important' : '#b3b3b3',
                fontWeight: activeTab === 1 ? 'bold' : 'normal',
              }}
            />
            <Tab
              label="Production"
              sx={{
                color: activeTab === 2 ? '#ffffff !important' : '#b3b3b3',
                fontWeight: activeTab === 2 ? 'bold' : 'normal',
              }}
            />
            <Tab
              label="Performance"
              sx={{
                color: activeTab === 3 ? '#ffffff !important' : '#b3b3b3',
                fontWeight: activeTab === 3 ? 'bold' : 'normal',
              }}
            />
            <Tab
              label="Proficiency"
              sx={{
                color: activeTab === 4 ? '#ffffff !important' : '#b3b3b3',
                fontWeight: activeTab === 4 ? 'bold' : 'normal',
              }}
            />
          </Tabs>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            marginRight: 50,
          }}>

            {activeTab === 0 && (
              <Box sx={{
                color: '#ceaf07',

              }}>


              </Box>
            )}
            {activeTab === 1 && (
              <>
                <Box sx={{
                  color: '#ceaf07',

                }}>


                </Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  ml: 1
                }}>

                </Box>


              </>
            )}
            {activeTab === 2 && (
              <>
                <Box sx={{
                  color: '#ceaf07',

                }}>


                </Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  ml: 1
                }}>


                </Box>


              </>
            )}
            {activeTab === 3 && (
              <Box sx={{
                color: '#ceaf07',

              }}>


              </Box>
            )}
            {activeTab === 4 && (
              <Box sx={{
                color: '#ceaf07',

              }}>


              </Box>
            )}


          </Box>

        </Box>

        {/* Tab Content */}
        <Box id="tab-box" sx={{ flexGrow: 1, overflow: 'hidden', p: 0, height: '100%' }}>
          {activeTab === 0 && <CustomerLandingPage selectedCustomer={selectedCustomer} setActiveTab={setActiveTab} />}
          {activeTab === 1 && <CustomerProfile selectedCustomer={selectedCustomer} />}
          {activeTab === 2 &&

            <>
              {!selectedCustomer ? (
                <Typography variant="body1" sx={{ p: 2 }}>
                  Please select a customer to view production data.
                </Typography>
              ) : loading ? (
                <Typography variant="body1" sx={{ p: 2 }}>
                  Loading Production Data...
                </Typography>
              ) : error ? (
                <Typography variant="body1" sx={{ p: 2, color: 'red' }}>
                  Error loading production data.
                </Typography>
              ) : data ? (
                <div>
                  <CalendarMasterDetail
                    businessKey={selectedCustomer.acctNo}
                    data={formattedProductionData}
                    currentYear={new Date().getFullYear()}
                    currentMonth={new Date().getMonth()}
                    onMonthChange={(year, month) => {
                      console.log(`Month changed to: ${month + 1}/${year}`);
                    }}
                    activities={activitiesConfig}
                  />
                </div>
              ) : null}
            </>
          }
          {activeTab === 3 && <Performance selectedCustomer={selectedCustomer} />}
          {activeTab === 4 && <Proficiency selectedCustomer={selectedCustomer} />}
        </Box>
      </Box>
    </CustomerDataContext.Provider>
  );
};

export default CustomerTabSet;
