import React, { useMemo, useState } from 'react';
import {
  Typography,
  Paper,
  Box,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Card,
  CardContent,
  Link,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../../../../hooks/useAuthenticatedData';
import config from '../../../../config';
import { enterpriseGridOverrideTheme } from '../../../../themes/EnterpriseGridTheme';
import MembershipPopup from '../MembershipPopup';
import SQLEditorPopup from '../SQLEditorPopup';

const ColorLegend = () => (
  <Card
    sx={{
      background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
      border: '1px solid #424242',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
      borderRadius: '12px',
      p: 1,
      width: '100%',
    }}
  >
    <Typography
      variant="h6"
      sx={{
        color: '#ffffff',
        fontWeight: 500,
        fontSize: '.9rem',
        mb: 0.5,
      }}
    >
      Legend
    </Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      {[
        { color: 'green', label: 'Margin ≥ 20%' },
        { color: 'yellow', label: 'Margin between 15% and 20%' },
        { color: 'red', label: 'Margin < 15%' },
      ].map(({ color, label }) => (
        <Box
          key={label}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            py: 0.25,
          }}
        >
          <Box
            sx={{
              width: '16px',
              height: '16px',
              backgroundColor: color,
              borderRadius: '2px',
              flexShrink: 0,
            }}
          />
          <Typography
            sx={{
              color: '#b0b0b0',
              fontSize: '0.7rem',
              lineHeight: 1.2,
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              minHeight: '16px',
            }}
          >
            {label}
          </Typography>
        </Box>
      ))}
    </Box>
  </Card>
);

function PurchasingDepartmentInventoryPlannedSpendDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  // State for pop-up dialogs
  const [openMembership, setOpenMembership] = useState(false);
  const [openSummaryQuery, setOpenSummaryQuery] = useState(false);
  const [openDetailsQuery, setOpenDetailsQuery] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const period = searchParams.get('period')?.toUpperCase() || 'MTD';

  const url = `${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/inventory-planned-spend-details?period=${period}`;
  const { data, isLoading, error } = useAuthenticatedData(url);

  const handleTabChange = (event, newPeriod) => {
    navigate({
      pathname: location.pathname,
      search: `?period=${newPeriod}`,
    });
  };

  const rows = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];
    return data.map((item, index) => ({
      id: index,
      ...item,
    }));
  }, [data]);

  const generateExportFileName = () => `Purchasing-${period}-inventory-spend-details`;

  const columns = useMemo(
    () => [
      {
        field: 'entered',
        headerName: 'Entered',
        width: 150,
        type: 'dateTime',
        valueFormatter: (params) => (params ? new Date(params).toLocaleDateString() : ''),
      },
      { field: 'partnumber', headerName: 'Part Number', width: 150 },
      { field: 'condition', headerName: 'Condition', width: 100 },
      { field: 'accountName', headerName: 'Vendor', width: 150 },
      { field: 'fullname', headerName: 'Buyer', width: 150 },
      { field: 'qtyOrdered', headerName: 'Qty', width: 80, type: 'number' },
      {
        field: 'unitPrice',
        headerName: 'Unit Price',
        width: 120,
        type: 'number',
        valueFormatter: (params) => (params != null ? `$${Number(params).toLocaleString()}` : ''),
      },
      {
        field: 'unitSellPrice',
        headerName: 'Unit Sell Price',
        width: 140,
        type: 'number',
        valueFormatter: (params) => (params != null ? `$${Number(params).toLocaleString()}` : ''),
      },
      {
        field: 'spend',
        headerName: 'Spend',
        width: 120,
        type: 'number',
        valueFormatter: (params) => (params != null ? `$${Number(params).toLocaleString()}` : ''),
      },
      {
        field: 'projectedRev',
        headerName: 'Projected Revenue',
        width: 160,
        type: 'number',
        valueFormatter: (params) => (params != null ? `$${Number(params).toLocaleString()}` : ''),
      },
      {
        field: 'projectedMargin',
        headerName: 'Projected Margin',
        width: 160,
        type: 'number',
        valueFormatter: (params) => (params != null ? `$${Number(params).toLocaleString()}` : ''),
      },
      {
        field: 'marginPercent',
        headerName: 'Margin %',
        width: 120,
        type: 'number',
        valueFormatter: (params) => (params != null ? `${Number(params).toFixed(2)}%` : ''),
      },
      { field: 'docNo', headerName: 'Pentagon Doc#', width: 120 },
      { field: 'userLine', headerName: 'Line', width: 80 },
      { field: 'docType', headerName: 'Doc Type', width: 140 },
    ],
    []
  );

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
      <Typography variant="h1" component="h1" gutterBottom align="left">
        Purchasing Scorecard - Inventory Planned Spend Details
      </Typography>

      {/* Technical options: small links for debugging */}
      <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <Link
          sx={{ color: 'gray' }}
          component="button"
          variant="caption"
          onClick={() => setOpenMembership(true)}
        >
          Who is Included?
        </Link>
        <Link
          sx={{ color: 'gray' }}
          component="button"
          variant="caption"
          onClick={() => setOpenSummaryQuery(true)}
        >
          Summary Query
        </Link>
        <Link
          sx={{ color: 'gray' }}
          component="button"
          variant="caption"
          onClick={() => setOpenDetailsQuery(true)}
        >
          Details Query
        </Link>
      </Box>

      {/* About the Data and Legend Block */}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          mb: 2,
        }}
      >
        <Box sx={{ flex: 1, minWidth: 300 }}>
          <Card
            sx={{
              background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
              border: '1px solid #424242',
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
              borderRadius: '12px',
              height: '100%',
            }}
          >
            <CardContent sx={{ p: 1 }}>
              <Typography
                variant="h6"
                sx={{
                  color: '#ffffff',
                  fontWeight: 500,
                  fontSize: '.9rem',
                  mb: 0.5,
                }}
              >
                About the Data
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: '#b0b0b0',
                  fontSize: '0.75rem',
                }}
              >
                These are purchase orders for parts to stock entered by the current purchasing team. You can see who's included by clicking the "Who is included?" link above. The price of the part as it existed in Pentagon at the time of the PO is used here - it doesn't matter what the sell price happens to be in Pentagon right now. The projected revenue is calculated by multiplying the quantity ordered by the projected sell price.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ flex: 1, minWidth: 300 }}>
          <ColorLegend />
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Tabs
          value={period}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{ mb: 2 }}
        >
          <Tab
            sx={{
              color: period === 'MTD' ? '#ffffff !important' : '#b3b3b3',
              fontWeight: period === 'MTD' ? 'bold' : 'normal',
            }}
            label="Month-to-Date"
            value="MTD"
          />
          <Tab
            sx={{
              color: period === 'QTD' ? '#ffffff !important' : '#b3b3b3',
              fontWeight: period === 'QTD' ? 'bold' : 'normal',
            }}
            label="Quarter-to-Date"
            value="QTD"
          />
          <Tab
            sx={{
              color: period === 'YTD' ? '#ffffff !important' : '#b3b3b3',
              fontWeight: period === 'YTD' ? 'bold' : 'normal',
            }}
            label="Year-to-Date"
            value="YTD"
          />
        </Tabs>
      </Box>

      <Paper elevation={3} sx={{ height: 600, width: '100%', p: 2 }}>
        <Box sx={{ height: '100%' }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
              <Typography sx={{ ml: 2 }}>Loading...</Typography>
            </Box>
          ) : error ? (
            <Alert severity="error">Error: {error.message}</Alert>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 25]}
              disableSelectionOnClick
              autoHeight={false}
              headerHeight={40}
              sx={{
                ...enterpriseGridOverrideTheme,
                '& .MuiDataGrid-row.green': {
                  backgroundColor: 'green !important',
                  color: 'white !important',
                  '&:hover': {
                    backgroundColor: 'green !important',
                  },
                  '& .MuiDataGrid-cell': {
                    backgroundColor: 'green !important',
                    color: 'white !important',
                  },
                },
                '& .MuiDataGrid-row.yellow': {
                  backgroundColor: 'yellow !important',
                  color: 'black !important',
                  '&:hover': {
                    backgroundColor: 'yellow !important',
                  },
                  '& .MuiDataGrid-cell': {
                    backgroundColor: 'yellow !important',
                    color: 'black !important',
                  },
                },
                '& .MuiDataGrid-row.red': {
                  backgroundColor: 'red !important',
                  color: 'white !important',
                  '&:hover': {
                    backgroundColor: 'red !important',
                  },
                  '& .MuiDataGrid-cell': {
                    backgroundColor: 'red !important',
                    color: 'white !important',
                  },
                },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  csvOptions: { fileName: generateExportFileName() },
                  printOptions: { fileName: generateExportFileName() },
                },
              }}
              getRowClassName={(params) => {
                const margin = Number(params.row.marginPercent);
                if (isNaN(margin)) return '';
                if (margin >= 20) return 'green';
                if (margin >= 15 && margin < 20) return 'yellow';
                if (margin < 15) return 'red';
                return '';
              }}
            />
          )}
        </Box>
      </Paper>

      {/* Pop-up dialogs */}
      <MembershipPopup open={openMembership} onClose={() => setOpenMembership(false)} />
      <SQLEditorPopup
        open={openSummaryQuery}
        onClose={() => setOpenSummaryQuery(false)}
        title="Summary Query"
        endpoint={`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/queries/inventory-planned-spend-summary-query`}
      />
      <SQLEditorPopup
        open={openDetailsQuery}
        onClose={() => setOpenDetailsQuery(false)}
        title="Details Query"
        endpoint={`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/queries/inventory-planned-spend-details-query`}
      />
    </Paper>
  );
}

export default PurchasingDepartmentInventoryPlannedSpendDetails;
