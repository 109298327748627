import React, { useMemo, useState } from 'react';
import { Box, Typography, CircularProgress, Alert, Paper, Tabs, Tab, Card, CardContent } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import config from '../../../config';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';


const ColorLegend = () => (
    <Card sx={{
        background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
        border: '1px solid #424242',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
        borderRadius: '12px',
        p: 1,
        width: '400px',
        mx: 2
    }}>
        <Typography
            variant="h6"
            sx={{
                color: '#ffffff',
                fontWeight: 500,
                fontSize: '.9rem',
                mb: 0.5
            }}
        >
            Legend
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            {[


                { color: '#73A57D', label: 'Pace 100% or more' },

                { color: '#D1A752', label: 'Pace 75%-100%' },
                { color: '#D88C8C', label: 'Pace less than 75%' },

            ].map(({ color, label }) => (
                <Box
                    key={label}
                    sx={{
                        display: 'flex',
                        alignItems: 'center', // Changed to center
                        gap: 1,
                        py: 0.25
                    }}
                >
                    <Box sx={{
                        width: '16px',
                        height: '16px',
                        backgroundColor: color,
                        borderRadius: '2px',
                        flexShrink: 0
                    }} />
                    <Typography sx={{
                        color: '#b0b0b0',
                        fontSize: '0.7rem',
                        lineHeight: 1.2,
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: '16px' // Match height of color box
                    }}>
                        {label}
                    </Typography>
                </Box>
            ))}
        </Box>
    </Card>
);

const SalesPerformanceScorecardBusinessGrowthPaceDetail = () => {
    const { ccode, mode } = useParams();
    const [activeTab, setActiveTab] = useState(mode);

    const navigate = useNavigate();
    const { data, isLoading, error } = useAuthenticatedData(`${config.apiBaseUrl}/sales/scorecard/${ccode}/newBusinessGrowthPace${mode.toUpperCase()}`);
    const { data: salesPersonName, isLoading: isLoadingName, error: errorName } = useAuthenticatedData(`${config.apiBaseUrl}/data/name?ccode=${ccode}`);

    const { columns, rows, totals } = useMemo(() => {
        if (!data) return { columns: [], rows: [], totals: {} };

        const previousYear = (new Date().getFullYear() - 1).toString();
        const currentYear = (new Date().getFullYear()).toString();
        const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);
        const previousQuarter = currentQuarter - 1 || 4;
        const previousQuarterYear = previousQuarter === 4 ? previousYear : currentYear;

        let paceHeaderName = `Pace for ${currentYear}`;
        let lastActualHeaderName = `${previousYear} Total`;
        let growthActualHeaderName = 'Year Over Year Growth Actual';
        let growthPaceHeaderName = 'Year Over Year Growth Pace';

        if (activeTab === 'qtd') {
            paceHeaderName = `Pace for Q${currentQuarter} ${currentYear}`;
            lastActualHeaderName = `Q${previousQuarter} ${previousQuarterYear} Total`;
            growthActualHeaderName = 'Quarter Over Quarter Growth Actual';
            growthPaceHeaderName = 'Quarter Over Quarter Growth Pace';
        } else if (activeTab === 'mtd') {
            const currentMonth = new Date().toLocaleString('en-US', { month: 'long' });
            const lastMonthDate = new Date();
            lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
            const lastMonth = lastMonthDate.toLocaleString('en-US', { month: 'long' });
            paceHeaderName = `Pace for ${currentMonth}`;
            lastActualHeaderName = `${lastMonth} Total`;
            growthActualHeaderName = 'Month Over Month Growth Actual';
            growthPaceHeaderName = 'Month Over Month Growth Pace';
        }

        const columns = [
            {
                field: 'account',
                headerName: 'Account',
                width: 300,
                sortable: true
            },

            {
                field: 'currentActual',
                headerName: 'Grand Total',
                width: 180,
                type: 'number',
                renderCell: (params) => {
                    return params.value?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    });
                }
            },

            {
                field: 'currentPace',
                headerName: paceHeaderName,
                width: 180,
                type: 'number',
                renderCell: (params) => {
                    return params.value?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    });
                }
            },

            {
                field: 'lastActual',
                headerName: lastActualHeaderName,
                width: 180,
                type: 'number',
                renderCell: (params) => {
                    return params.value?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    });
                }
            },

            {
                field: 'growthActual',
                headerName: growthActualHeaderName,
                width: 250,
                type: 'number',
                renderCell: (params) => {
                    return `${new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(params.value)}%`;
                }
            },

            {
                field: 'growthPace',
                headerName: growthPaceHeaderName,
                width: 250,
                type: 'number',
                renderCell: (params) => {
                    return `${new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(params.value)}%`;
                }
            }


        ];

        // Calculate sums first
        const currentActualSum = data.reduce((sum, account) => sum + (account.currentActual || 0), 0);
        const currentPaceSum = data.reduce((sum, account) => sum + (account.currentPace || 0), 0);
        const lastActualSum = data.reduce((sum, account) => sum + (account.lastActual || 0), 0);


        // Calculate totals
        const totals = {
            currentActual: data.reduce((sum, account) => sum + (account.currentActual || 0), 0),
            currentPace: data.reduce((sum, account) => sum + (account.currentPace || 0), 0),
            lastActual: data.reduce((sum, account) => sum + (account.lastActual || 0), 0),
            growthActual: lastActualSum !== 0
                ? Number((currentActualSum / lastActualSum * 100).toFixed(2))
                : 0,
            growthPace: lastActualSum !== 0
                ? Number((currentPaceSum / lastActualSum * 100).toFixed(2))
                : 0,

            //    growthActual: Number((data.reduce((sum, account) => sum + (account.growthActual || 0), 0) / data.length).toFixed(2)),
            //    growthPace: Number((data.reduce((sum, account) => sum + (account.growthPace || 0), 0) / data.length).toFixed(2))
        };

        // Create rows
        const regularRows = data.map(account => ({
            id: account.accountNumber,
            account: `${account.accountName} (${account.accountNumber})`,
            colorCategory: account.colorCategory,
            currentActual: account.currentActual || 0,
            currentPace: account.currentPace || 0,
            lastActual: account.lastActual || 0,
            growthActual: account.growthActual || 0,
            growthPace: account.growthPace || 0
        })).sort((a, b) => a.account.localeCompare(b.account));


        // Add totals row
        const rows = [
            ...regularRows,
            {
                id: 'TOTAL',
                account: 'TOTAL',
                colorCategory: 'total',
                currentActual: totals.currentActual,
                currentPace: totals.currentPace,
                lastActual: totals.lastActual,
                growthActual: totals.growthActual,
                growthPace: totals.growthPace
            }
        ];

        return { columns, rows, totals };
    }, [data, mode, activeTab]);

    const handleTabChange = (event, newValue) => {

        navigate(`/sales/salesperson/${ccode}/scorecard/new-business-growth-pace/${newValue}`);
        setActiveTab(newValue);
        console.log('Active Tab:', activeTab);
    };

    if (isLoading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error.message}</Alert>;

    return (
        <Paper elevation={3} sx={{ p: 2, maxWidth: '100%', mt: 4 }}>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',

            }}>
                <Typography variant="h1" component="h1" gutterBottom align="left">
                    Growth Pace Detail - {salesPersonName}
                </Typography>

                <Card sx={{
                    width: '50%',
                    background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)', // Dark gradient
                    border: '1px solid #424242',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
                    borderRadius: '12px',
                    p: 0
                }}>
                    <CardContent
                        sx={{
                            p: 1,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#ffffff',
                                fontWeight: 500,
                                fontSize: '.9rem'
                            }}
                        >
                            About the Data
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: '#b0b0b0',
                                fontSize: '0.75rem' // Slightly smaller text
                            }}
                        >
                            This shows all sales to the accounts assigned to {salesPersonName}, regardless of who made the sale.
                            Currently you're seeing all sales {mode.toUpperCase()} and how they compare to the last period.
                            The Grand Total is the current actual sales for the selected period. Using business days and correctly accounting for holidays, that is projected
                            forward to determine the pace for the period.


                        </Typography>
                    </CardContent>
                </Card>

                <ColorLegend />
            </Box>

            <Tabs
                value={mode}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                sx={{ mb: 2 }}
            >
                <Tab label="Month-to-Date" value="mtd" sx={{
                    color: activeTab === 'mtd' ? '#ffffff !important' : '#b3b3b3',
                    fontWeight: activeTab === 'mtd' ? 'bold' : 'normal',
                }} />
                <Tab label="Quarter-to-Date" value="qtd" sx={{
                    color: activeTab === 'qtd' ? '#ffffff !important' : '#b3b3b3',
                    fontWeight: activeTab === 'qtd' ? 'bold' : 'normal',
                }} />
                <Tab label="Year-to-Date" value="ytd" sx={{
                    color: activeTab === 'ytd' ? '#ffffff !important' : '#b3b3b3',
                    fontWeight: activeTab === 'ytd' ? 'bold' : 'normal',
                }} />
            </Tabs>

            <Box sx={{ height: 800, width: '100%' }}>
                <DataGrid
                    density='compact'
                    rows={rows}
                    columns={columns}
                    getRowClassName={(params) => {
                        if (params.row.id === 'TOTAL') return 'row-total';
                        return `row-${params.row.colorCategory?.toLowerCase()}`;
                    }}
                    sx={{
                        ...enterpriseGridOverrideTheme,
                        // Custom row styles that will override the default row styling:
                        '& .MuiDataGrid-cell': {
                            display: 'flex',
                            alignItems: 'center',
                            color: 'black',
                            fontWeight: 700,

                        },
                        '& .MuiDataGrid-row.row-red': {
                            backgroundColor: '#D88C8C !important', // Soft Coral
                            '& .MuiDataGrid-cell': {
                                backgroundColor: '#D88C8C !important'
                            }
                        },
                        '& .MuiDataGrid-row.row-green': {
                            backgroundColor: '#73A57D !important', // Sage Green
                            '& .MuiDataGrid-cell': {
                                backgroundColor: '#73A57D !important'
                            }
                        },
                        '& .MuiDataGrid-row.row-yellow': {
                            backgroundColor: '#D1A752 !important', // Warm Gold
                            '& .MuiDataGrid-cell': {
                                backgroundColor: '#D1A752 !important'
                            }
                        },

                        '& .MuiDataGrid-row.row-total': {
                            backgroundColor: '#e0e0e0 !important',
                            fontWeight: 'bold !important',
                            '& .MuiDataGrid-cell': {
                                backgroundColor: '#e0e0e0 !important'
                            }
                        },

                        // Optionally keep your pointer and selected styling:
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer',
                            '&.Mui-selected': {
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            },
                        },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            csvOptions: { fileName: 'export' },
                            printOptions: { fileName: 'export' },
                        },
                    }}

                    hideFooter
                />
            </Box>
        </Paper>
    );
};

export default SalesPerformanceScorecardBusinessGrowthPaceDetail;