import React, { useMemo, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  Tabs,
  Tab,
  Card,
  CardContent,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import config from '../../../config';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';
import Salesperson from './Salesperson';


const ColorLegend = ({ mode }) => {
  const legendData = mode === '3m' 
    ? [
        { color: '#73A57D', label: 'Sales in all 3 months of the past 3 months' },
        { color: '#D1A752', label: 'Sales in 2 of the past 3 months' },
        { color: '#D88C8C', label: 'Sales in 1 or fewer of the past 3 months' }
      ]
    : [
        { color: '#73A57D', label: 'Sales in 83% or more of the past 12 months' },
        { color: '#D1A752', label: 'Sales in 50-82% of the past 12 months' },
        { color: '#D88C8C', label: 'Sales in less than 50% of the past 12 months' }
      ];

  return (
    <Card sx={{
      background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
      border: '1px solid #424242',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
      borderRadius: '12px',
      p: 1,
      width: '400px',
      mx: 2
    }}>
      <Typography
        variant="h6"
        sx={{
          color: '#ffffff',
          fontWeight: 500,
          fontSize: '.9rem',
          mb: 0.5
        }}
      >
        Legend
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        {legendData.map(({ color, label }) => (
          <Box
            key={label}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              py: 0.25
            }}
          >
            <Box sx={{
              width: '16px',
              height: '16px',
              backgroundColor: color,
              borderRadius: '2px',
              flexShrink: 0
            }} />
            <Typography sx={{
              color: '#b0b0b0',
              fontSize: '0.7rem',
              lineHeight: 1.2,
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              minHeight: '16px'
            }}>
              {label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  );
};


const SalesPerformanceScorecardRetentionDetail = () => {
  const { ccode, mode } = useParams();
  const [activeTab, setActiveTab] = useState(mode);
  const navigate = useNavigate();
  const { data: salesPersonName, isLoading: isLoadingName, error: errorName } = useAuthenticatedData(`${config.apiBaseUrl}/data/name?ccode=${ccode}`);


  const computeDateRange = (mode) => {
    const now = new Date();
    let start;
    switch (mode) {
      case '3m': // current month (partial) plus last 2 full months
        start = new Date(now.getFullYear(), now.getMonth() - 2, 1);
        break;
      case '12m': // current month (partial) plus last 11 full months
        start = new Date(now.getFullYear(), now.getMonth() - 11, 1);
        break;
      default:
        // fallback option (could also default to 3m, or another period as needed)
        start = new Date(now.getFullYear(), now.getMonth() - 2, 1);
        break;
    }
    return {
      from: encodeURIComponent(start.toISOString()),
      to: encodeURIComponent(now.toISOString()),
    };
  };

  // Memoize the date range so it only recalculates when activeTab changes.
  const { from, to } = useMemo(() => computeDateRange(activeTab), [activeTab]);

  // Build the API endpoint using the computed date range.
  const endpoint = `${config.apiBaseUrl}/sales/scorecard/${ccode}/retentionDetails?from=${from}&to=${to}`;

  const { data, isLoading, error } = useAuthenticatedData(endpoint);

  // Formatter for currency values (no cents, dollars only)
  const formatCurrency = (value) => {
    if (value == null) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(value);
  };

  /**
   * We group records by customer and pivot the soRevenue by month.
   * For each row we add an "Account Total" field.
   * Additionally, we compute a totals object (totalRow) that sums each revenue column.
   */
  const { columns, rows, totalRow } = useMemo(() => {
    if (!data) return { columns: [], rows: [], totalRow: {} };

    // Group data by accountNumber.
    const groups = {};
    data.forEach((item) => {
      const key = item.accountNumber;
      if (!groups[key]) {
        groups[key] = {
          id: key,
          customer: item.accountName,
          colorCategory: item.colorCategory,
          revenues: {},
        };
      }
      groups[key].revenues[item.yearMonth] =
        (groups[key].revenues[item.yearMonth] || 0) + item.soRevenue;
    });

    let computedColumns = [];
    let computedRows = [];
    let revenueKeys = []; // list of revenue month keys

    // Helper to format a Date as YYYY-MM.
    const formatYM = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      return `${year}-${month}`;
    };

    if (activeTab === '3m') {
      // For the 3-month view, we want revenue columns with YYYY-MM labels.
      const now = new Date();
      const currentYM = formatYM(now);
      const prev1YM = formatYM(new Date(now.getFullYear(), now.getMonth() - 1, 1));
      const prev2YM = formatYM(new Date(now.getFullYear(), now.getMonth() - 2, 1));
      revenueKeys = [prev2YM, prev1YM, currentYM];

      computedColumns = [
        { field: 'customer', headerName: 'Customer', width: 300 },
        ...revenueKeys.map((m) => ({
          field: m,
          headerName: m,
          width: 150,
          valueFormatter: (params) => formatCurrency(params),
        })),
        {
          field: 'accountTotal',
          headerName: 'Account Total',
          width: 150,
          valueFormatter: (params) => formatCurrency(params),
        },
      ];

      computedRows = Object.values(groups)
        .map((group) => {
          const row = {
            id: group.id,
            customer: group.customer,
            colorCategory: group.colorCategory,
          };
          let total = 0;
          revenueKeys.forEach((key) => {
            const value = group.revenues[key] || 0;
            row[key] = value;
            total += value;
          });
          row.accountTotal = total;
          return row;
        })
        .sort((a, b) => a.customer.localeCompare(b.customer));

    } else if (activeTab === '12m') {
      // For the 12-month view, we need one column per month plus the Customer and Account Total columns.
      const now = new Date();
      const months = [];
      const startDate = new Date(now.getFullYear(), now.getMonth() - 11, 1);
      for (let i = 0; i < 12; i++) {
        const d = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1);
        months.push(formatYM(d));
      }
      revenueKeys = months;

      computedColumns = [
        { field: 'customer', headerName: 'Customer', width: 300 },
        ...months.map((m) => ({
          field: m,
          headerName: m,
          width: 150,
          valueFormatter: (params) => formatCurrency(params),
        })),
        {
          field: 'accountTotal',
          headerName: 'Account Total',
          width: 150,
          valueFormatter: (params) => formatCurrency(params),
        },
      ];

      computedRows = Object.values(groups).map((group) => {
        const row = {
          id: group.id,
          customer: group.customer,
          colorCategory: group.colorCategory,
        };
        let total = 0;
        months.forEach((m) => {
          const value = group.revenues[m] || 0;
          row[m] = value;
          total += value;
        });
        row.accountTotal = total;
        return row;
      });
    }

    // Compute totals across all rows.
    const totals = revenueKeys.reduce((acc, key) => {
      acc[key] = computedRows.reduce((sum, row) => sum + (row[key] || 0), 0);
      return acc;
    }, {});
    totals.accountTotal = computedRows.reduce(
      (sum, row) => sum + (row.accountTotal || 0),
      0
    );

    // Use an object for totalRow that matches the column fields.
    const totalRow = { ...totals, id: 'total', customer: 'Month Total' };

    return { columns: computedColumns, rows: computedRows, totalRow };
  }, [data, activeTab]);

  const handleTabChange = (event, newValue) => {
    navigate(`/sales/salesperson/${ccode}/scorecard/retention/${newValue}`);
    setActiveTab(newValue);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <Paper elevation={3} sx={{ p: 2, maxWidth: '100%', mt: 4 }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',

      }}>
        <Typography variant="h1" component="h1" gutterBottom align="left">
          Retention Detail - {salesPersonName}
        </Typography>
        <Card sx={{
          width: '50%',
          background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)', // Dark gradient
          border: '1px solid #424242',
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
          borderRadius: '12px',
          p: 0
        }}>
          <CardContent
            sx={{
              p: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: '#ffffff',
                fontWeight: 500,
                fontSize: '.9rem'
              }}
            >
              About the Data
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#b0b0b0',
                fontSize: '0.75rem' // Slightly smaller text
              }}
            >
              These are all of the accounts assigned to {salesPersonName} and all additional accounts they sold to, regardless of to whom they may have been assigned.
              Only the sales made by {salesPersonName} to these accounts are counted here - if someone else sold to these accounts, you won't see that here.
              On the top-level view, the percentage there is the percent of assigned accounts that {salesPersonName} has sold to in the past 3 or 12 months. Even selling $1
              will count as a sale, but only if it's made by {salesPersonName}.

            </Typography>
          </CardContent>
        </Card>

        <ColorLegend mode={activeTab} />
      </Box>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        sx={{ mb: 2 }}
      >
        <Tab
          label="3 Months"
          value="3m"
          sx={{
            color: activeTab === '3m' ? '#ffffff !important' : '#b3b3b3',
            fontWeight: activeTab === '3m' ? 'bold' : 'normal',
          }}
        />

        <Tab
          label="12 Months"
          value="12m"
          sx={{
            color: activeTab === '12m' ? '#ffffff !important' : '#b3b3b3',
            fontWeight: activeTab === '12m' ? 'bold' : 'normal',
          }}
        />
      </Tabs>

      <Box sx={{ height: 800, width: '100%' }}>
        <DataGrid
          density="compact"
          rows={rows}
          columns={columns}
          getRowClassName={(params) =>
            params.row ? `row-${params.row.colorCategory?.toLowerCase()}` : ''
          }
          sx={{
            ...enterpriseGridOverrideTheme,
            '& .MuiDataGrid-cell': {
              display: 'flex',
              alignItems: 'center',
              color: 'black',
              fontWeight: 700,
            },
            '& .MuiDataGrid-row.row-red': {
              backgroundColor: '#D88C8C !important',
              '& .MuiDataGrid-cell': {
                backgroundColor: '#D88C8C !important',
              },
            },
            '& .MuiDataGrid-row.row-green': {
              backgroundColor: '#73A57D !important',
              '& .MuiDataGrid-cell': {
                backgroundColor: '#73A57D !important',
              },
            },
            '& .MuiDataGrid-row.row-yellow': {
              backgroundColor: '#D1A752 !important',
              '& .MuiDataGrid-cell': {
                backgroundColor: '#D1A752 !important',
              },
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              '&.Mui-selected': {
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: { fileName: 'export' },
              printOptions: { fileName: 'export' },
            },
          }}
          hideFooter
        />
      </Box>

      {/* Custom Total Row */}
      <Box
        sx={{
          display: 'flex',
          borderTop: '1px solid #ccc',
          padding: 1,
          marginTop: 1,
          backgroundColor: '#222628',
          fontWeight: 'bold',
        }}
      >
        {columns.map((col) => (
          <Box key={col.field} sx={{ width: col.width, paddingLeft: 1, fontSize: 14 }}>
            {col.field === 'customer'
              ? totalRow.customer
              : formatCurrency(totalRow[col.field])}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default SalesPerformanceScorecardRetentionDetail;
