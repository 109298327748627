import React from 'react';
import { Box, Typography, CircularProgress, Alert, Tooltip } from '@mui/material';
import useAuthenticatedData from '../../../../hooks/useAuthenticatedData';
import config from '../../../../config';

const PurchasingDepartmentPerformancePanel = () => {
    const { data, isLoading, error } = useAuthenticatedData(`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard`);

    // Map the status from the API to actual colors for background and border.
    const statusColorMapping = {
        green: { background: '#2A7040', border: '#1E5A33' },
        yellow: { background: '#A07D32', border: '#7C6025' },
        red: { background: '#944848', border: '#7A3636' },
        gray: { background: '#4F4F4F', border: '#3B3B3B' }
    };

    // Colors for key target boxes
    const keyTargetColors = {
        background: '#605d3d', // Darker background for key targets
        border: '#8C6B1F'      // Dark Bronze for key target borders
    };

    const formatCurrency = (value) => {
        if (value === null || value === undefined) return 'N/A';
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    };

    const formatPercent = (value) => {
        if (value === null || value === undefined) return 'N/A';
        if (isNaN(value)) return 'N/A';
        return `${Math.round(value)}%`;
    };

    const formatNumber = (value) => {
        if (value === null || value === undefined) return 'N/A';
        return new Intl.NumberFormat('en-US').format(value);
    };

    // Updated function with an optional thresholdFactor parameter.
    const getStatusColor = (value, target, isHigherBetter = true, thresholdFactor = 0.8) => {
        if (value === null || value === undefined) return 'gray';

        if (isHigherBetter) {
            if (value >= target) return 'green';
            if (value >= target * thresholdFactor) return 'yellow';
            return 'red';
        } else {
            if (value <= target) return 'green';
            if (value <= target * (2 - thresholdFactor)) return 'yellow';
            return 'red';
        }
    };

    // New helper function for percent-based margins.
    const getMarginPercentStatus = (value) => {
        if (value === null || value === undefined || isNaN(value)) return 'gray';
        if (value >= 20) return 'green';
        if (value >= 15) return 'yellow';
        return 'red';
    };

    // Function specifically for inventory health color coding remains unchanged.
    const getInventoryHealthStatus = (value, ageBucket) => {
        const goals = {
            '0-90': 56,
            '91-180': 26,
            '181-365': 16,
            '366+': 2
        };

        const goal = goals[ageBucket];

        if (ageBucket === '0-90') {
            if (value >= goal) return 'green';
            if (value >= goal * 0.85) return 'yellow';
            return 'red';
        } else {
            if (value <= goal) return 'green';
            if (value <= goal * 1.15) return 'yellow';
            return 'red';
        }
    };

    // Helper functions for handling clicks.
    const handleInventoryPlannedSpendClick = (period) => {
        window.open(`/supply-chain/purchasing/department/scorecard/inventory-planned-spend-details?period=${period}`, '_blank');
    };

    const handleInventoryLandedSpendClick = (period) => {
        window.open(`/supply-chain/purchasing/department/scorecard/inventory-landed-spend-details?period=${period}`, '_blank');
    };

    const handleInventoryRevenueClick = (period) => {
        window.open(`/supply-chain/purchasing/department/scorecard/inventory-revenue-details?period=${period}`, '_blank');
    };
    const handleInventoryHealthClick = (period) => {
        window.open(`/supply-chain/purchasing/department/scorecard/inventory-health-details?period=${period}`, '_blank');
    };
    const handleNewVendorPOClick = (period) => {
        window.open(`/supply-chain/purchasing/department/scorecard/new-vendor-po-details?period=${period}`, '_blank');
    };
    const handleNewVendorRFQClick = (period) => {
        window.open(`/supply-chain/purchasing/department/scorecard/new-vendor-rfq-details?period=${period}`, '_blank');
    };

    // The CompactMetricBox component with support for onClick.
    const CompactMetricBox = ({ value, format, status, onClick, tooltip }) => {
        const safeValue = value ?? 0;
        const hasData = safeValue !== null && !isNaN(safeValue);
        const { background, border } = statusColorMapping[status] || { background: '#4F4F4F', border: '#3B3B3B' };

        const formattedValue =
            format === 'currency'
                ? formatCurrency(hasData ? safeValue : 0)
                : format === 'percent'
                    ? formatPercent(hasData ? safeValue : 0)
                    : hasData
                        ? format === 'number'
                            ? formatNumber(safeValue)
                            : safeValue.toFixed(1)
                        : 'N/A';

        return (
            <Tooltip
                title={tooltip || ''}
                sx={{
                    bgcolor: 'white',
                    color: 'black',
                    '& .MuiTooltip-arrow': {
                        color: 'white'
                    }
                }}
            >
                <span style={{ display: 'inline-block' }}>
                    <Box
                        onClick={onClick}
                        sx={{
                            bgcolor: background,
                            p: 1,
                            borderRadius: 1,
                            border: 2,
                            borderColor: border,
                            width: '120px',
                            height: '40px',
                            textAlign: 'center',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: onClick ? 'pointer' : 'default'
                        }}
                    >
                        <Typography variant="h6" color="white" sx={{ fontWeight: 600, fontSize: '.85rem' }}>
                            {formattedValue}
                        </Typography>
                    </Box>
                </span>
            </Tooltip>
        );
    };


    if (isLoading)
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                <CircularProgress />
            </Box>
        );
    if (error)
        return (
            <Alert severity="error">
                Error loading purchasing scorecard data: {error.message}
            </Alert>
        );
    if (!data)
        return <Alert severity="info">No purchasing scorecard data available</Alert>;

    // Duplicate the Inventory Spend metric into Planned and Landed sections.
    const metrics = [
        {
            // Change label to Planned Inventory Spend.
            title: 'Planned Inventory Spend',
            goalDetails: [
                'Goal',
                'Margin %: 23.4%',

            ],
            rows: [
                {
                    label: 'Spend',
                    boxes: [
                        {
                            title: 'Month to Date',
                            value: data.inventoryPlannedSpendMTD,
                            status: 'gray', // Change this to always be gray

                            format: 'currency',
                            mode: 'MTD'
                        },
                        {
                            title: 'Qtr to Date',
                            value: data.inventoryPlannedSpendQTD,
                            status: 'gray', // Change this to always be gray

                            format: 'currency',
                            mode: 'QTD'
                        },
                        {
                            title: 'Year to Date',
                            value: data.inventoryPlannedSpendYTD,
                            status: 'gray', // Change this to always be gray

                            format: 'currency',
                            mode: 'YTD'
                        }
                    ]
                },
                {
                    label: 'Projected Margin %',
                    boxes: [
                        {
                            title: 'Month to Date',
                            value: data.inventoryPlannedSpendProjectedMarginPercentMTD,
                            status: getMarginPercentStatus(data.inventoryPlannedSpendProjectedMarginPercentMTD),
                            format: 'percent',
                            mode: 'MTD'
                        },
                        {
                            title: 'Qtr to Date',
                            value: data.inventoryPlannedSpendProjectedMarginPercentQTD,
                            status: getMarginPercentStatus(data.inventoryPlannedSpendProjectedMarginPercentQTD),
                            format: 'percent',
                            mode: 'QTD'
                        },
                        {
                            title: 'Year to Date',
                            value: data.inventoryPlannedSpendProjectedMarginPercentYTD,
                            status: getMarginPercentStatus(data.inventoryPlannedSpendProjectedMarginPercentYTD),
                            format: 'percent',
                            mode: 'YTD'
                        }
                    ]
                },
                {
                    label: 'Projected Margin $',
                    boxes: [
                        {
                            title: 'Month to Date',
                            value: data.inventoryPlannedSpendProjectedMarginDollarsMTD,
                            status: 'gray', // Change this to always be gray

                            format: 'currency',
                            mode: 'MTD'
                        },
                        {
                            title: 'Qtr to Date',
                            value: data.inventoryPlannedSpendProjectedMarginDollarsQTD,
                            status: 'gray', // Change this to always be gray

                            format: 'currency',
                            mode: 'QTD'
                        },
                        {
                            title: 'Year to Date',
                            value: data.inventoryPlannedSpendProjectedMarginDollarsYTD,
                            status: 'gray', // Change this to always be gray

                            format: 'currency',
                            mode: 'YTD'
                        }
                    ]
                }
            ],
            // Duplicate the same rows for Landed Inventory Spend for now.
            landed: {
                title: 'Landed Inventory Spend',
                goalDetails: [
                    'Annual Goal',
                    'Spend Goal: $10.95M',
                    'Margin %: 23.4%',
                    'Margin $: $300K'
                ],
                rows: [
                    {
                        label: 'Spend',
                        boxes: [
                            {
                                title: 'Month to Date',
                                value: data.inventoryLandedSpendMTD,
                                status: getStatusColor(data.inventoryLandedSpendMTD, 912500, true, 0.75),
                                format: 'currency',
                                mode: 'MTD'
                            },
                            {
                                title: 'Qtr to Date',
                                value: data.inventoryLandedSpendQTD,
                                status: getStatusColor(data.inventoryLandedSpendQTD, 2737500, true, 0.75),
                                format: 'currency',
                                mode: 'QTD'
                            },
                            {
                                title: 'Year to Date',
                                value: data.inventoryLandedSpendYTD,
                                status: getStatusColor(data.inventoryLandedSpendYTD, 10950000, true, 0.75),
                                format: 'currency',
                                mode: 'YTD'
                            }
                        ]
                    },
                    {
                        label: 'Projected Margin %',
                        boxes: [
                            {
                                title: 'Month to Date',
                                value: data.inventoryLandedSpendProjectedMarginPercentMTD,
                                status: getMarginPercentStatus(data.inventoryLandedSpendProjectedMarginPercentMTD),
                                format: 'percent',
                                mode: 'MTD'
                            },
                            {
                                title: 'Qtr to Date',
                                value: data.inventoryLandedSpendProjectedMarginPercentQTD,
                                status: getMarginPercentStatus(data.inventoryLandedSpendProjectedMarginPercentQTD),
                                format: 'percent',
                                mode: 'QTD'
                            },
                            {
                                title: 'Year to Date',
                                value: data.inventoryLandedSpendProjectedMarginPercentYTD,
                                status: getMarginPercentStatus(data.inventoryLandedSpendProjectedMarginPercentYTD),
                                format: 'percent',
                                mode: 'YTD'
                            }
                        ]
                    },
                    {
                        label: 'Projected Margin $',
                        boxes: [
                            {
                                title: 'Month to Date',
                                value: data.inventoryLandedSpendProjectedMarginDollarsMTD,
                                status: getStatusColor(data.inventoryLandedSpendProjectedMarginDollarsMTD, 300000, true, 0.75),
                                format: 'currency',
                                mode: 'MTD'
                            },
                            {
                                title: 'Qtr to Date',
                                value: data.inventoryLandedSpendProjectedMarginDollarsQTD,
                                status: getStatusColor(data.inventoryLandedSpendProjectedMarginDollarsQTD, 900000, true, 0.75),
                                format: 'currency',
                                mode: 'QTD'
                            },
                            {
                                title: 'Year to Date',
                                value: data.inventoryLandedSpendProjectedMarginDollarsYTD,
                                status: getStatusColor(data.inventoryLandedSpendProjectedMarginDollarsYTD, 3600000, true, 0.75),
                                format: 'currency',
                                mode: 'YTD'
                            }
                        ]
                    }
                ]
            }
        },
        {
            title: 'Revenue/Margin',
            goalDetails: [
                'Monthly Goal',
                'Rev: $1.1M',
                'Margin %: 23.4%',
                'Margin $: $300K'
            ],
            rows: [
                {
                    label: 'Revenue',
                    boxes: [
                        {
                            title: 'Month to Date',
                            value: data.revenueMTD,
                            status: getStatusColor(data.revenueMTD, 1100000, true, 0.75),
                            format: 'currency',
                            mode: 'MTD'
                        },
                        {
                            title: 'Qtr to Date',
                            value: data.revenueQTD,
                            status: getStatusColor(data.revenueQTD, 3300000, true, 0.75),
                            format: 'currency',
                            mode: 'QTD'
                        },
                        {
                            title: 'Year to Date',
                            value: data.revenueYTD,
                            status: getStatusColor(data.revenueYTD, 13200000, true, 0.75),
                            format: 'currency',
                            mode: 'YTD'
                        }
                    ]
                },
                {
                    label: 'Gross Margin %',
                    boxes: [
                        {
                            title: 'Month to Date',
                            value: data.revenueGrossMarginPercentMTD,
                            status: getMarginPercentStatus(data.revenueGrossMarginPercentMTD),
                            format: 'percent',
                            mode: 'MTD'
                        },
                        {
                            title: 'Qtr to Date',
                            value: data.revenueGrossMarginPercentQTD,
                            status: getMarginPercentStatus(data.revenueGrossMarginPercentQTD),
                            format: 'percent',
                            mode: 'QTD'
                        },
                        {
                            title: 'Year to Date',
                            value: data.revenueGrossMarginPercentYTD,
                            status: getMarginPercentStatus(data.revenueGrossMarginPercentYTD),
                            format: 'percent',
                            mode: 'YTD'
                        }
                    ]
                },
                {
                    label: 'Gross Margin $',
                    boxes: [
                        {
                            title: 'Month to Date',
                            value: data.revenueGrossMarginDollarsMTD,
                            status: getStatusColor(data.revenueGrossMarginDollarsMTD, 300000, true, 0.75),
                            format: 'currency',
                            mode: 'MTD'
                        },
                        {
                            title: 'Qtr to Date',
                            value: data.revenueGrossMarginDollarsQTD,
                            status: getStatusColor(data.revenueGrossMarginDollarsQTD, 900000, true, 0.75),
                            format: 'currency',
                            mode: 'QTD'
                        },
                        {
                            title: 'Year to Date',
                            value: data.revenueGrossMarginDollarsYTD,
                            status: getStatusColor(data.revenueGrossMarginDollarsYTD, 3600000, true, 0.75),
                            format: 'currency',
                            mode: 'YTD'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Inventory Health',
            goalDetails: [
                '0-90 56%',
                '91-180 26%',
                '181-365 16%',
                '366+ 2%'
            ],
            rows: [
                {
                    label: '0-90',
                    boxes: [
                        {
                            title: 'Current',
                            value: data.inventoryHealthPercent90,
                            status: getInventoryHealthStatus(data.inventoryHealthPercent90, '0-90'),
                            format: 'percent'
                        }
                    ]
                },
                {
                    label: '91-180',
                    boxes: [
                        {
                            title: 'Current',
                            value: data.inventoryHealthPercent180,
                            status: getInventoryHealthStatus(data.inventoryHealthPercent180, '91-180'),
                            format: 'percent'
                        }
                    ]
                },
                {
                    label: '181-365',
                    boxes: [
                        {
                            title: 'Current',
                            value: data.inventoryHealthPercent365,
                            status: getInventoryHealthStatus(data.inventoryHealthPercent365, '181-365'),
                            format: 'percent'
                        }
                    ]
                },
                {
                    label: '366+',
                    boxes: [
                        {
                            title: 'Current',
                            value: data.inventoryHealthPercent365Plus,
                            status: getInventoryHealthStatus(data.inventoryHealthPercent365Plus, '366+'),
                            format: 'percent'
                        }
                    ]
                }
            ]
        },
        {
            title: 'New Vendor Base Growth',
            goalDetails: [
                'Annual Goal',
                'Increase Vendor base by 84'
            ],
            rows: [
                {
                    label: '# of PO Actual',
                    boxes: [
                        {
                            title: 'Month to Date',
                            value: data.newVendorPOActualMTD,
                            status: getStatusColor(data.newVendorPOActualMTD, 18, true, 14 / 18),
                            format: 'number',
                            mode: 'MTD'
                        },
                        {
                            title: 'Qtr to Date',
                            value: data.newVendorPOActualQTD,
                            status: getStatusColor(data.newVendorPOActualQTD, 54, true, 40 / 54),
                            format: 'number',
                            mode: 'QTD'
                        },
                        {
                            title: 'Year to Date',
                            value: data.newVendorPOActualYTD,
                            status: getStatusColor(data.newVendorPOActualYTD, 225, true, 168 / 225),
                            format: 'number',
                            mode: 'YTD'
                        }
                    ]
                },
                {
                    label: '# of PO Pace',
                    boxes: [
                        {
                            title: 'Month to Date',
                            value: data.newVendorPOPaceMTD,
                            status: 'gray',
                            format: 'percent'
                        },
                        {
                            title: 'Qtr to Date',
                            value: data.newVendorPOPaceQTD,
                            status: 'gray',
                            format: 'percent'
                        },
                        {
                            title: 'Year to Date',
                            value: data.newVendorPOPaceYTD,
                            status: 'gray',
                            format: 'percent'
                        }
                    ]
                },
                {
                    label: '# of RFQs',
                    boxes: [
                        {
                            title: 'Month to Date',
                            value: data.newVendorRFQActualMTD,
                            status: 'gray',
                            format: 'number',
                            mode: 'MTD',
                            tooltip: 'xyz',
                        },
                        {
                            title: 'Qtr to Date',
                            value: data.newVendorRFQActualQTD,
                            status: 'gray',
                            format: 'number',
                            mode: 'QTD'
                        },
                        {
                            title: 'Year to Date',
                            value: data.newVendorRFQActualYTD,
                            status: 'gray',
                            format: 'number',
                            mode: 'YTD'
                        }
                    ]
                }
            ],
            directSource: "TBD"
        }
    ];


    // Here's the corrected version that fixes the 'row' is not defined errors

    // This is a more focused fix that keeps your original layout but just adjusts 
    // the position of the Landed Inventory section next to Planned Inventory

    return (
        <Box sx={{ bgcolor: '#121212', p: 0, borderRadius: 2, width: '100%', position: 'relative' }}>
            {metrics.map((metric, metricIndex) => (
                <Box
                    key={metric.title}
                    sx={{
                        mb: 2,
                        bgcolor: '#1A1A1A',
                        p: 0,
                        borderRadius: 1,
                        position: 'relative'
                    }}
                >
                    {/* Special handling for the first metric (Planned Inventory Spend) */}
                    {metricIndex === 0 ? (
                        <Box sx={{ display: 'flex' }}>
                            {/* Left side - Planned Inventory */}
                            <Box sx={{ width: '50%' }}>
                                <Box sx={{ display: 'flex', mb: 1 }}>
                                    <Box sx={{ width: '150px' }}>
                                        <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500, fontSize: '1rem' }}>
                                            {metric.title}
                                        </Typography>
                                        {metric.goalDetails.map((detail, index) => (
                                            <Typography
                                                key={index}
                                                variant="subtitle2"
                                                color="lightgray"
                                                sx={{ display: 'block', fontWeight: 400, fontSize: '.8rem' }}
                                            >
                                                {detail}
                                            </Typography>
                                        ))}
                                    </Box>

                                    <Box sx={{ flex: 1 }}>
                                        {metric.rows.map((row, rowIndex) => (
                                            <Box
                                                key={rowIndex}
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '140px 120px 120px 120px',
                                                    gap: 1,
                                                    mb: 1,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Typography
                                                    color="lightgray"
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '.85rem',
                                                        alignSelf: 'center',
                                                        textAlign: 'right',
                                                        pr: 1
                                                    }}
                                                >
                                                    {row.label}
                                                </Typography>

                                                {row.boxes.map((box, index) => (
                                                    <CompactMetricBox
                                                        key={index}
                                                        value={box.value}
                                                        status={box.status}
                                                        format={box.format}
                                                        tooltip={box.tooltip}
                                                        onClick={
                                                            (metric.title === 'Planned Inventory Spend' && box.mode)
                                                                ? () => handleInventoryPlannedSpendClick(box.mode)
                                                                : undefined
                                                        }
                                                    />
                                                ))}
                                            </Box>
                                        ))}

                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: '140px 120px 120px 120px',
                                                gap: 1,
                                                mt: 0.5
                                            }}
                                        >
                                            <Box />
                                            <Typography variant="body2" color="lightgray" sx={{ textAlign: 'center' }}>
                                                Month to Date
                                            </Typography>
                                            <Typography variant="body2" color="lightgray" sx={{ textAlign: 'center' }}>
                                                Qtr to Date
                                            </Typography>
                                            <Typography variant="body2" color="lightgray" sx={{ textAlign: 'center' }}>
                                                Year to Date
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            {/* Right side - Landed Inventory */}
                            {metric.landed && (
                                <Box sx={{ width: '50%' }}>
                                    <Box sx={{ display: 'flex', mb: 1 }}>
                                        <Box sx={{ width: '150px' }}>
                                            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500, fontSize: '1rem' }}>
                                                {metric.landed.title}
                                            </Typography>
                                            {metric.landed.goalDetails.map((detail, index) => (
                                                <Typography
                                                    key={index}
                                                    variant="subtitle2"
                                                    color="lightgray"
                                                    sx={{ display: 'block', fontWeight: 400, fontSize: '.8rem' }}
                                                >
                                                    {detail}
                                                </Typography>
                                            ))}
                                        </Box>

                                        <Box sx={{ flex: 1 }}>
                                            {metric.landed.rows.map((row, rowIndex) => (
                                                <Box
                                                    key={rowIndex}
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: '140px 120px 120px 120px',
                                                        gap: 1,
                                                        mb: 1,
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Typography
                                                        color="lightgray"
                                                        sx={{
                                                            fontWeight: 500,
                                                            fontSize: '.85rem',
                                                            alignSelf: 'center',
                                                            textAlign: 'right',
                                                            pr: 1
                                                        }}
                                                    >
                                                        {row.label}
                                                    </Typography>

                                                    {row.boxes.map((box, index) => (
                                                        <CompactMetricBox
                                                            key={index}
                                                            value={box.value}
                                                            status={box.status}
                                                            format={box.format}
                                                            onClick={
                                                                (metric.landed.title === 'Landed Inventory Spend' && box.mode)
                                                                    ? () => handleInventoryLandedSpendClick(box.mode)
                                                                    : undefined
                                                            }
                                                        />
                                                    ))}
                                                </Box>
                                            ))}

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '140px 120px 120px 120px',
                                                    gap: 1,
                                                    mt: 0.5
                                                }}
                                            >
                                                <Box />
                                                <Typography variant="body2" color="lightgray" sx={{ textAlign: 'center' }}>
                                                    Month to Date
                                                </Typography>
                                                <Typography variant="body2" color="lightgray" sx={{ textAlign: 'center' }}>
                                                    Qtr to Date
                                                </Typography>
                                                <Typography variant="body2" color="lightgray" sx={{ textAlign: 'center' }}>
                                                    Year to Date
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        // Normal rendering for other metrics (Revenue/Margin, Inventory Health, etc.)
                        <Box sx={{ display: 'flex', mb: 1 }}>
                            <Box sx={{ width: '150px' }}>
                                <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500, fontSize: '1rem' }}>
                                    {metric.title}
                                </Typography>
                                {metric.goalDetails.map((detail, index) => (
                                    <Typography
                                        key={index}
                                        variant="subtitle2"
                                        color="lightgray"
                                        sx={{ display: 'block', fontWeight: 400, fontSize: '.8rem' }}
                                    >
                                        {detail}
                                    </Typography>
                                ))}
                            </Box>

                            <Box sx={{ flex: 1 }}>
                                {metric.rows.map((row, rowIndex) => (
                                    <Box
                                        key={rowIndex}
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '140px 120px 120px 120px',
                                            gap: 1,
                                            mb: 1,
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            color="lightgray"
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: '.85rem',
                                                alignSelf: 'center',
                                                textAlign: 'right',
                                                pr: 1
                                            }}
                                        >
                                            {row.label}
                                        </Typography>

                                        {row.boxes.map((box, index) => (
                                            <CompactMetricBox
                                                key={index}
                                                value={box.value}
                                                status={box.status}
                                                format={box.format}
                                                onClick={
                                                    (metric.title === 'Revenue/Margin' && box.mode)
                                                        ? () => handleInventoryRevenueClick(box.mode)
                                                        : (metric.title === 'Inventory Health')
                                                            ? () => handleInventoryHealthClick('current')
                                                            : (metric.title === 'New Vendor Base Growth' && row.label === '# of PO Actual' && box.mode)
                                                                ? () => handleNewVendorPOClick(box.mode)
                                                                : (metric.title === 'New Vendor Base Growth' && row.label === '# of RFQs' && box.mode)
                                                                    ? () => handleNewVendorRFQClick(box.mode)
                                                                    : undefined
                                                }
                                            />
                                        ))}
                                    </Box>
                                ))}

                                {metric.rows && metric.rows.length > 0 && metric.rows[0].boxes && metric.rows[0].boxes.length > 1 && (
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '140px 120px 120px 120px',
                                            gap: 1,
                                            mt: 0.5
                                        }}
                                    >
                                        <Box />
                                        <Typography variant="body2" color="lightgray" sx={{ textAlign: 'center' }}>
                                            Month to Date
                                        </Typography>
                                        <Typography variant="body2" color="lightgray" sx={{ textAlign: 'center' }}>
                                            Qtr to Date
                                        </Typography>
                                        <Typography variant="body2" color="lightgray" sx={{ textAlign: 'center' }}>
                                            Year to Date
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}

                    {metric.title === 'New Vendor Base Growth' && (
                        <Box
                            sx={{
                                position: 'absolute',
                                right: 16,
                                bottom: 16,
                                bgcolor: '#333',
                                borderRadius: 1,
                                p: 1,
                                width: 80,
                                textAlign: 'center'
                            }}
                        >
                            <Typography color="lightgray" sx={{ fontSize: '.7rem' }}>
                                Direct Source
                            </Typography>
                            <Typography color="white" sx={{ fontWeight: 700, fontSize: '1.2rem' }}>
                                {metric.directSource || 'TBD'}
                            </Typography>
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default PurchasingDepartmentPerformancePanel;
