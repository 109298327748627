import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, CircularProgress, Alert, Paper, Tabs, Tab, Card, CardContent } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import config from '../../../config';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';

const ColorLegend = () => (
    <Card sx={{
        background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
        border: '1px solid #424242',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
        borderRadius: '12px',
        p: 1,
        width: '400px',
        mx: 2
    }}>
        <Typography
            variant="h6"
            sx={{
                color: '#ffffff',
                fontWeight: 500,
                fontSize: '.9rem',
                mb: 0.5
            }}
        >
            Legend
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            {[
                { color: '#73A57D', label: 'Boiling - Sales each week for the past 90 days (13 weeks)' },
                { color: '#73A57D', label: 'Hot - Sales for each of the past 3 months' },
                { color: '#D1A752', label: 'Warm - Sales in the last month or in both the two previous months' },
                { color: '#D6937E', label: 'Cold - Sales in the past year' },

                { color: '#5d90dc', label: 'Ice - No sales in a year' },
            ].map(({ color, label }) => (
                <Box
                    key={label}
                    sx={{
                        display: 'flex',
                        alignItems: 'center', // Changed to center
                        gap: 1,
                        py: 0.25
                    }}
                >
                    <Box sx={{
                        width: '16px',
                        height: '16px',
                        backgroundColor: color,
                        borderRadius: '2px',
                        flexShrink: 0
                    }} />
                    <Typography sx={{
                        color: '#b0b0b0',
                        fontSize: '0.7rem',
                        lineHeight: 1.2,
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: '16px' // Match height of color box
                    }}>
                        {label}
                    </Typography>
                </Box>
            ))}
        </Box>
    </Card>
);

const SalesPerformanceScorecardRevenueDetail = () => {
    const { ccode, mode } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const { data, isLoading, error } = useAuthenticatedData(`${config.apiBaseUrl}/sales/scorecard/${ccode}/revenue${mode.toUpperCase()}`);
    const { data: salesPersonName, isLoading: isLoadingName, error: errorName } = useAuthenticatedData(`${config.apiBaseUrl}/data/name?ccode=${ccode}`);

    const { columns, rows, totals } = useMemo(() => {
        if (!data) return { columns: [], rows: [], totals: {} };

        const timePeriod = mode === 'qtd' ? 'week' : mode === 'ytd' ? 'month' : 'day';

        // Create a map to store unique periods with their timestamps
        const periodsMap = new Map();

        data.forEach(account => {
            account.activities.forEach(activity => {
                const date = new Date(activity.activityDate);
                let display, timestamp;

                if (timePeriod === 'week') {
                    const startOfWeek = new Date(date);
                    startOfWeek.setDate(date.getDate() - date.getDay());
                    display = startOfWeek.toLocaleDateString();
                    timestamp = startOfWeek.getTime();
                } else if (timePeriod === 'month') {
                    display = `${date.getFullYear()}-${date.getMonth() + 1}`;
                    timestamp = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
                } else {
                    display = date.toLocaleDateString();
                    timestamp = date.getTime();
                }

                if (!periodsMap.has(display)) {
                    periodsMap.set(display, timestamp);
                }
            });
        });

        // Convert map to array and sort by timestamp (oldest first)
        const allPeriods = Array.from(periodsMap, ([display, timestamp]) => ({
            display,
            timestamp
        })).sort((a, b) => a.timestamp - b.timestamp); // Changed sort order here

        const columns = [
            { field: 'account', headerName: 'Account', width: 250 },
            ...allPeriods.map(period => ({
                field: period.display,
                headerName: period.display,
                width: 130,
                type: 'number',
                renderCell: (params) => {
                    return params.value === 0 ? '' : params.value?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    });
                }
            })),
            {
                field: 'total',
                headerName: 'Total',
                width: 130,
                type: 'number',
                renderCell: (params) => {
                    return params.value === 0 ? '' : params.value?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    });
                }
            }
        ];

        const getRowClassName = (params) => {
            switch (params.row.Health_Text.toLowerCase()) {
                case 'ice': return 'row-ice';
                case 'cold': return 'row-cold';
                case 'warm': return 'row-warm';
                case 'hot': return 'row-hot';
                case 'boiling': return 'row-boiling';
                default: return '';
            }
        };

        // Create rows and calculate totals
        const rows = data.map(account => {
            const rowData = {
                id: account.accountNumber,
                account: `${account.accountName} (${account.accountNumber})`,
                colorCategory: account.colorCategory,
                total: 0
            };

            // Initialize period totals
            allPeriods.forEach(period => {
                rowData[period.display] = account.activities
                    .filter(activity => {
                        const date = new Date(activity.activityDate);
                        if (timePeriod === 'week') {
                            const startOfWeek = new Date(date);
                            startOfWeek.setDate(date.getDate() - date.getDay());
                            return startOfWeek.toLocaleDateString() === period.display;
                        } else if (timePeriod === 'month') {
                            return `${date.getFullYear()}-${date.getMonth() + 1}` === period.display;
                        }
                        return date.toLocaleDateString() === period.display;
                    })
                    .reduce((sum, activity) => sum + activity.activityAmount, 0);
                rowData.total += rowData[period.display];
            });

            return rowData;
        });

        rows.sort((a, b) => a.account.localeCompare(b.account));

        // Calculate period totals
        const totals = allPeriods.reduce((acc, period) => {
            acc[period.display] = rows.reduce((sum, row) => sum + (row[period.display] || 0), 0);
            return acc;
        }, {});

        // Add totals row
        rows.push({
            id: 'TOTAL',
            account: 'TOTAL',
            colorCategory: 'total',
            ...totals,
            total: Object.values(totals).reduce((sum, val) => sum + val, 0)
        });

        return { columns, rows, totals };
    }, [data, mode]);

    const handleTabChange = (event, newValue) => {
        navigate(`/sales/salesperson/${ccode}/scorecard/revenue/${newValue}`);
    };


    const getRowClassName = (params) => {
        const category = params.row.colorCategory.toLowerCase();
        if (category === 'total' || params.row.id === 'TOTAL') {
            return 'row-total';
        }
        switch (category) {
            case 'ice': return 'row-ice';
            case 'cold': return 'row-cold';
            case 'warm': return 'row-warm';
            case 'hot': return 'row-hot';
            case 'boiling': return 'row-boiling';
            default: return '';
        }
    };
    

    if (isLoading || isLoadingName) return <CircularProgress />;
    if (error) return <Alert severity="error">{error.message}</Alert>;

    return (
        <Paper elevation={3} sx={{ p: 2, maxWidth: '100%', mt: 2 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',

            }}>
                <Typography variant="h1" component="h1" gutterBottom align="left">
                    Revenue Detail - {salesPersonName}
                </Typography>

                <Card sx={{
                    width: '50%',
                    background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)', // Dark gradient
                    border: '1px solid #424242',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
                    borderRadius: '12px',
                    p: 0
                }}>
                    <CardContent
                        sx={{
                            p: 1,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#ffffff',
                                fontWeight: 500,
                                fontSize: '.9rem'
                            }}
                        >
                            About the Data
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: '#b0b0b0',
                                fontSize: '0.75rem' // Slightly smaller text
                            }}
                        >
                            These are all sales orders for {salesPersonName},  {mode.toUpperCase()}, regardless of whether the customers have been assigned to them.
                            

                        </Typography>
                    </CardContent>
                </Card>

                <ColorLegend />
            </Box>

            <Tabs
                value={mode}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                sx={{ mb: 2 }}
            >
                <Tab sx={{
                    color: mode === 'mtd' ? '#ffffff !important' : '#b3b3b3',
                    fontWeight: mode === 'mtd' ? 'bold' : 'normal',
                }} label="Month-to-Date" value="mtd" />
                <Tab sx={{
                    color: mode === 'qtd' ? '#ffffff !important' : '#b3b3b3',
                    fontWeight: mode === 'qtd' ? 'bold' : 'normal',
                }} label="Quarter-to-Date" value="qtd" />
                <Tab sx={{
                    color: mode === 'ytd' ? '#ffffff !important' : '#b3b3b3',
                    fontWeight: mode === 'ytd' ? 'bold' : 'normal',
                }} label="Year-to-Date" value="ytd" />
            </Tabs>

            <Box sx={{ height: 800, width: '100%' }}>
                <DataGrid
                    density='compact'
                    rows={rows}
                    columns={columns}
                    getRowClassName={getRowClassName}

                    sx={{
                        ...enterpriseGridOverrideTheme,
                        '& .MuiDataGrid-cell': {
                            display: 'flex',
                            alignItems: 'center',
                            color: 'black',
                            fontWeight: 700,
                        },
                        '& .MuiDataGrid-row.row-ice': {
                            backgroundColor: '#5d90dc !important',
                            '& .MuiDataGrid-cell': {
                                backgroundColor: '#5d90dc !important'
                            }
                        },
                        '& .MuiDataGrid-row.row-cold': {
                            backgroundColor: '#D6937E !important',
                            '& .MuiDataGrid-cell': {
                                backgroundColor: '#D6937E !important'
                            }
                        },
                        '& .MuiDataGrid-row.row-warm': {
                            backgroundColor: '#D1A752 !important',
                            '& .MuiDataGrid-cell': {
                                backgroundColor: '#D1A752 !important'
                            }
                        },
                        '& .MuiDataGrid-row.row-hot': {
                            backgroundColor: '#73A57D !important',
                            '& .MuiDataGrid-cell': {
                                backgroundColor: '#73A57D !important'
                            }
                        },
                        '& .MuiDataGrid-row.row-boiling': {
                            backgroundColor: '#73A57D !important',
                            '& .MuiDataGrid-cell': {
                                backgroundColor: '#73A57D !important'
                            }
                        },
                        '& .MuiDataGrid-row.row-total': {
                            backgroundColor: '#e0e0e0 !important',
                            fontWeight: 'bold !important',
                            '& .MuiDataGrid-cell': {
                                backgroundColor: '#e0e0e0 !important'
                            }
                        },
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer',
                            '&.Mui-selected': {
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            },
                        },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            csvOptions: { fileName: 'export' },
                            printOptions: { fileName: 'export' },
                        },
                    }}
                />
            </Box>
        </Paper>
    );
};

export default SalesPerformanceScorecardRevenueDetail;