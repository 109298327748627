import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  Tabs,
  Tab,
  Grid, Card, CardContent
} from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import config from '../../../config';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';


const ColorLegend = () => (
  <Card sx={{
    background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
    border: '1px solid #424242',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
    borderRadius: '12px',
    p: 1,
    width: '400px',
    mx: 2
  }}>
    <Typography
      variant="h6"
      sx={{
        color: '#ffffff',
        fontWeight: 500,
        fontSize: '.9rem',
        mb: 0.5
      }}
    >
      Legend
    </Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      {[


        { color: '#73A57D', label: '-2% or more' },

        { color: '#D1A752', label: '-2% - -5%' },
        { color: '#D88C8C', label: 'Less than -5%' },

      ].map(({ color, label }) => (
        <Box
          key={label}
          sx={{
            display: 'flex',
            alignItems: 'center', // Changed to center
            gap: 1,
            py: 0.25
          }}
        >
          <Box sx={{
            width: '16px',
            height: '16px',
            backgroundColor: color,
            borderRadius: '2px',
            flexShrink: 0
          }} />
          <Typography sx={{
            color: '#b0b0b0',
            fontSize: '0.7rem',
            lineHeight: 1.2,
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            minHeight: '16px' // Match height of color box
          }}>
            {label}
          </Typography>
        </Box>
      ))}
    </Box>
  </Card>
);


const SalesPerformanceScorecardPricingDetail = () => {
  const { ccode, mode } = useParams();
  const navigate = useNavigate();

  // Normalize mode to lower case.
  const normalizedMode = mode.toLowerCase();

  // Endpoints for the three modes.
  const mtdEndpoint = `${config.apiBaseUrl}/sales/scorecard/${ccode}/pricingdetails/mtd`;
  const summaryEndpoint = `${config.apiBaseUrl}/sales/scorecard/${ccode}/pricing${normalizedMode.toUpperCase()}Summary`;


  const { data: salesPersonName, isLoading: isLoadingName, error: errorName } = useAuthenticatedData(`${config.apiBaseUrl}/data/name?ccode=${ccode}`);


  const calculateDetailTotals = (details) => {
    if (!details || details.length === 0) return null;

    const totals = {
      id: 'TOTAL',
      accountName: 'TOTAL',
      qty: 0,
      unitPrice: 0,
      discountAmount: 0,
      extendedSalePrice: 0,
      pentagonSellPrice: 0,
      amountDifference: 0,
      percentDifference: 0,
    };

    details.forEach(row => {
      totals.qty += row.qty || 0;
      totals.discountAmount += row.discountAmount || 0;
      totals.extendedSalePrice += row.extendedSalePrice || 0;
      totals.pentagonSellPrice += row.pentagonSellPrice || 0;
      totals.amountDifference += row.amountDifference || 0;
    });

    // Calculate averages
   // totals.unitPrice = totals.qty ? totals.extendedSalePrice / totals.qty : 0;
    totals.percentDifference = totals.pentagonSellPrice ? (totals.extendedSalePrice / totals.pentagonSellPrice) * 100 : 0;
    totals.percentDifference = totals.percentDifference - 100;
 
    return totals;
  };

  // Detail endpoint URL will be built once a summary row is selected.
  // (e.g., pricingQTD/detail?fromDate=...&toDate=...)

  // For MTD, we only need the pricing detail endpoint.
  const {
    data: mtdData,
    isLoading: mtdLoading,
    error: mtdError
  } = useAuthenticatedData(normalizedMode === 'mtd' ? mtdEndpoint : null);

  // For QTD or YTD, first load the summary data.
  const {
    data: summaryData,
    isLoading: summaryLoading,
    error: summaryError
  } = useAuthenticatedData(
    normalizedMode !== 'mtd' ? summaryEndpoint : null
  );

  // State for selected summary row (for QTD/YTD).
  const [selectedSummary, setSelectedSummary] = useState(null);
  // Build a detail endpoint URL when a summary row is selected.
  const detailEndpoint =
    selectedSummary &&
    `${config.apiBaseUrl}/sales/scorecard/${ccode}/pricing${normalizedMode.toUpperCase()}/detail?fromDate=${encodeURIComponent(
      selectedSummary.start
    )}&toDate=${encodeURIComponent(selectedSummary.end)}`;

  // Load detail data (for QTD and YTD) when a summary row is selected.
  const {
    data: detailData,
    isLoading: detailLoading,
    error: detailError
  } = useAuthenticatedData(normalizedMode !== 'mtd' ? detailEndpoint : null, {
    // Refetch on change of detailEndpoint.
    deps: [detailEndpoint]
  });

  // Handler for tab change (navigates to a different mode)
  const handleTabChange = (event, newValue) => {
    navigate(`/sales/salesperson/${ccode}/scorecard/pricing/${newValue}`);
  };

  // Common columns for the pricing detail table
  const pricingDetailColumns = [
    { field: 'accountName', headerName: 'Account', width: 300 },


    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      valueFormatter: (params) =>
        params ? new Date(params).toLocaleDateString() : ''
    },
    { field: 'partnumber', headerName: 'Partnumber' },
    { field: 'qty', headerName: 'Qty', type: 'number' },
    {
      field: 'unitPrice',
      headerName: 'Unit Price',
      type: 'number',
      valueFormatter: (params) =>
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(params)
    },
    {
      field: 'discountAmount',
      headerName: 'Discount',
      type: 'number',
      valueFormatter: (params) =>
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(params)
    },
    {
      field: 'extendedSalePrice',
      headerName: 'Extended Price',
      type: 'number',
      width: 120,
      valueFormatter: (params) =>
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(params)
    },
    {
      field: 'pentagonSellPrice',
      headerName: 'Pentagon Price',
      type: 'number',
      width: 120,
      valueFormatter: (params) =>
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(params)
    },
    {
      field: 'amountDifference',
      headerName: 'Amount Diff',
      type: 'number',
      valueFormatter: (params) =>
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(params)
    },
    {
      field: 'percentDifference',
      headerName: 'Percent Diff',
      type: 'number',
      valueFormatter: (params) => `${params.toFixed(2)}%`
    },
    { field: 'condition', headerName: 'Condition' },
    { field: 'class', headerName: 'Class' },

    { field: 'accountNumber', headerName: 'Account Number' },

  ];

  // Columns for the summary table (for QTD and YTD).
  const summaryColumns = [

    {
      field: 'start',
      headerName: 'From',
      width: 100,
      valueFormatter: (params) =>
        new Date(params).toLocaleDateString()
    },
    {
      field: 'end',
      headerName: 'To',
      width: 100,
      valueFormatter: (params) =>
        new Date(params).toLocaleDateString()
    },
    {
      field: 'avgPercentDifference',
      headerName: 'Avg. % Diff',
      type: 'number',

      valueFormatter: (params) => `${params.toFixed(2)}%`
    },
    {
      field: 'totalAmtDifference',
      headerName: 'Total Amount Diff',
      type: 'number',
      width: 130,
      valueFormatter: (params) =>
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(params)
    },

  ];

  // Define the tab labels and values.
  const tabValues = [
    { label: 'Month-to-Date', value: 'mtd' },
    { label: 'Quarter-to-Date', value: 'qtd' },
    { label: 'Year-to-Date', value: 'ytd' }
  ];



  // Render loading and error states
  if (normalizedMode === 'mtd' && mtdLoading) return <CircularProgress />;
  if (normalizedMode === 'mtd' && mtdError)
    return <Alert severity="error">{mtdError.message}</Alert>;
  if (normalizedMode !== 'mtd' && summaryLoading) return <CircularProgress />;
  if (normalizedMode !== 'mtd' && summaryError)
    return <Alert severity="error">{summaryError.message}</Alert>;


  if (normalizedMode !== 'mtd' && !summaryData) {
    return <CircularProgress />;
  }



  return (
    <Paper elevation={3} sx={{ p: 2, maxWidth: '100%', mt: 4 }}>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',

      }}>
        <Typography variant="h1" component="h1" gutterBottom align="left">
          Pricing {normalizedMode.toUpperCase()} - {salesPersonName}
        </Typography>

        <Card sx={{
          width: '50%',
          background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)', // Dark gradient
          border: '1px solid #424242',
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
          borderRadius: '12px',
          p: 0
        }}>
          <CardContent
            sx={{
              p: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: '#ffffff',
                fontWeight: 500,
                fontSize: '.9rem'
              }}
            >
              About the Data
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#b0b0b0',
                fontSize: '0.75rem' // Slightly smaller text
              }}
            >
              These are invoices sold by {salesPersonName}, regardless of who the customer was for all A & B parts. 
              This shows the pricing for each transaction and the difference between the Pentagon price and the actual sale price.

            </Typography>
          </CardContent>
        </Card>

        <ColorLegend />
      </Box>

      <Tabs
        value={normalizedMode}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        sx={{ mb: 2 }}
      >
        {tabValues.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            sx={{
              color: normalizedMode === tab.value ? '#ffffff !important' : '#b3b3b3',
              fontWeight: normalizedMode === tab.value ? 'bold' : 'normal',
            }}
          />
        ))}
      </Tabs>

      {normalizedMode === 'mtd' ? (
        // MTD: Show a single detail table.
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            density='compact'
            //   rows={mtdData || []}
            // rows={[...(mtdData || []), calculateDetailTotals(mtdData)]}
            rows={mtdData ? [...mtdData, calculateDetailTotals(mtdData)].filter(Boolean) : []}


            columns={pricingDetailColumns}
            getRowId={(row) => row.id}
            getRowClassName={(params) => {
              if (params.row.id === 'TOTAL') return 'row-total';
              return `row-${params.row.colorCategory?.toLowerCase()}`;
            }}
            sx={{
              ...enterpriseGridOverrideTheme,
              // Custom row styles that will override the default row styling:
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                color: 'black',
                fontWeight: 700,

              },
              '& .MuiDataGrid-row.row-red': {
                backgroundColor: '#D88C8C !important', // Soft Coral
                '& .MuiDataGrid-cell': {
                  backgroundColor: '#D88C8C !important'
                }
              },
              '& .MuiDataGrid-row.row-green': {
                backgroundColor: '#73A57D !important', // Sage Green
                '& .MuiDataGrid-cell': {
                  backgroundColor: '#73A57D !important'
                }
              },
              '& .MuiDataGrid-row.row-yellow': {
                backgroundColor: '#D1A752 !important', // Warm Gold
                '& .MuiDataGrid-cell': {
                  backgroundColor: '#D1A752 !important'
                }
              },

              '& .MuiDataGrid-row.row-total': {
                backgroundColor: '#e0e0e0 !important',
                fontWeight: 'bold !important',
                '& .MuiDataGrid-cell': {
                  backgroundColor: '#e0e0e0 !important'
                }
              },

              // Optionally keep your pointer and selected styling:
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                '&.Mui-selected': {
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                },
              },
            }}
          />
        </Box>
      ) : (
        // QTD and YTD: Show a two-pane layout with summary and detail tables.
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {normalizedMode === 'qtd'
                ? 'Weekly Summary'
                : 'Monthly Summary'}
            </Typography>
            <Box sx={{ height: 400, width: '100%' }}>
              <DataGrid
                density='compact'
                rows={summaryData || []}
                columns={summaryColumns}
                getRowId={(row) => row.id} // using the date as a key
                onRowClick={(params) => {
                  setSelectedSummary(params.row);
                }}
                getRowClassName={(params) => {
                  if (params.row.id === 'TOTAL') return 'row-total';
                  return `row-${params.row.rowColor?.toLowerCase()}`;
                }}
                sx={{
                  ...enterpriseGridOverrideTheme,
                  // Custom row styles that will override the default row styling:
                  '& .MuiDataGrid-cell': {
                    display: 'flex',
                    alignItems: 'center',
                    color: 'black',
                    fontWeight: 700,

                  },
                  '& .MuiDataGrid-row.row-red': {
                    backgroundColor: '#D88C8C !important', // Soft Coral
                    '& .MuiDataGrid-cell': {
                      backgroundColor: '#D88C8C !important'
                    }
                  },
                  '& .MuiDataGrid-row.row-green': {
                    backgroundColor: '#73A57D !important', // Sage Green
                    '& .MuiDataGrid-cell': {
                      backgroundColor: '#73A57D !important'
                    }
                  },
                  '& .MuiDataGrid-row.row-yellow': {
                    backgroundColor: '#D1A752 !important', // Warm Gold
                    '& .MuiDataGrid-cell': {
                      backgroundColor: '#D1A752 !important'
                    }
                  },

                  '& .MuiDataGrid-row.row-total': {
                    backgroundColor: '#e0e0e0 !important',
                    fontWeight: 'bold !important',
                    '& .MuiDataGrid-cell': {
                      backgroundColor: '#e0e0e0 !important'
                    }
                  },

                  // Optionally keep your pointer and selected styling:
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    '&.Mui-selected': {
                      backgroundColor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                    },
                  },
                }}


              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Transaction Details
              {selectedSummary && (
                <span style={{ marginLeft: '16px', fontWeight: 'normal', fontSize: '0.9em', color: '#666' }}>
                  ({new Date(selectedSummary.start).toLocaleDateString()} - {new Date(selectedSummary.end).toLocaleDateString()})
                </span>
              )}
            </Typography>
            {selectedSummary ? (
              detailLoading ? (
                <CircularProgress />
              ) : detailError ? (
                <Alert severity="error">{detailError.message}</Alert>
              ) : (
                <Box sx={{ height: 400, width: '100%' }}>
                  <DataGrid
                    density='compact'
                    //   rows={detailData ? [...detailData, calculateDetailTotals(detailData)].filter(Boolean) : []}
                    rows={detailData ? [...detailData, calculateDetailTotals(detailData)] : []}

                    columns={pricingDetailColumns}
                    getRowId={(row) => row.id}
                    getRowClassName={(params) => {
                      if (params.row.id === 'TOTAL' || params.row.accountName === 'TOTAL') return 'row-total';
                      return `row-${params.row.colorCategory?.toLowerCase()}`;
                    }}
                    sx={{
                      ...enterpriseGridOverrideTheme,
                      // Custom row styles that will override the default row styling:
                      '& .MuiDataGrid-cell': {
                        display: 'flex',
                        alignItems: 'center',
                        color: 'black',
                        fontWeight: 700,

                      },
                      '& .MuiDataGrid-row.row-red': {
                        backgroundColor: '#D88C8C !important', // Soft Coral
                        '& .MuiDataGrid-cell': {
                          backgroundColor: '#D88C8C !important'
                        }
                      },
                      '& .MuiDataGrid-row.row-green': {
                        backgroundColor: '#73A57D !important', // Sage Green
                        '& .MuiDataGrid-cell': {
                          backgroundColor: '#73A57D !important'
                        }
                      },
                      '& .MuiDataGrid-row.row-yellow': {
                        backgroundColor: '#D1A752 !important', // Warm Gold
                        '& .MuiDataGrid-cell': {
                          backgroundColor: '#D1A752 !important'
                        }
                      },

                      '& .MuiDataGrid-row.row-total': {
                        backgroundColor: '#e0e0e0 !important',
                        fontWeight: 'bold !important',
                        '& .MuiDataGrid-cell': {
                          backgroundColor: '#e0e0e0 !important'
                        }
                      },

                      // Optionally keep your pointer and selected styling:
                      '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        '&.Mui-selected': {
                          backgroundColor: 'primary.main',
                          '&:hover': {
                            backgroundColor: 'primary.dark',
                          },
                        },
                      },
                    }}

                  />
                </Box>
              )
            ) : (
              <Alert severity="info" icon={<InfoIcon style={{ color: "darkblue" }} />}>
                Please click a summary row to view detail transactions.
              </Alert>
            )}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default SalesPerformanceScorecardPricingDetail;