// PODayDetails.js
import React, { useMemo, useState } from 'react';
import { Typography, Paper, Box, Link } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import useAuthenticatedData from '../../../../../hooks/useAuthenticatedData';
import config from '../../../../../config';
import { enterpriseGridOverrideTheme } from '../../../../../themes/EnterpriseGridTheme';
import { useSearchParams } from 'react-router-dom';

import MembershipPopup from '../../MembershipPopup.js';
import SQLEditorPopup from '../../SQLEditorPopup.js';

const PODayDetails = ({ date: propDate }) => {
    const [searchParams] = useSearchParams();

    // State for technical pop-ups
    const [openMembership, setOpenMembership] = useState(false);
    const [openSummaryQuery, setOpenSummaryQuery] = useState(false);
    const [openDetailsQuery, setOpenDetailsQuery] = useState(false);

    // Compute the effective date using useMemo.
    const computedDate = useMemo(() => {
        if (propDate) return propDate;
        const dateString = searchParams.get('date');
        return dateString ? new Date(dateString) : null;
    }, [propDate, searchParams]);

    // Format the date as MM/DD/YYYY. If computedDate is null, default to an empty string.
    const formattedDate = computedDate
        ? computedDate.toLocaleDateString('en-US')
        : '';

    // Build the endpoint string. If no valid date is available, pass an empty string.
    const endpoint = formattedDate
        ? `${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/get-pos-for-day?date=${formattedDate}`
        : '';

    // Fetch data using the authenticated hook.
    const { data, isLoading, error } = useAuthenticatedData(endpoint);

    // Prepare rows; if the API returns an "id", use it, otherwise fallback to the index.
    const rows = useMemo(() => {
        if (!data) return [];
        return data.map((item, index) => ({
            id: item.id || index,
            ...item,
        }));
    }, [data]);

    // Generate the export filename as: po-<MM>-<DD>-<YYYY>-for-day
    const generateExportFileName = () => {
        if (!formattedDate) return 'po-no-date';
        // Convert formattedDate (MM/DD/YYYY) into its components.
        const [month, day, year] = formattedDate.split('/');
        return `po-${month}-${day}-${year}-for-day`;
    };

    // Define columns to display the purchase order details.
    const columns = useMemo(
        () => [
            { field: 'docNo', headerName: 'Doc#', width: 120 },
            { field: 'userLine', headerName: 'Line', width: 80 },
            { field: 'acctNo', headerName: 'Account', width: 120 },
            { field: 'accountName', headerName: 'Account Name', width: 150 },
            { field: 'fullName', headerName: 'Full Name', width: 150 },
           
            { field: 'partNumber', headerName: 'Part Number', width: 150 },
            { field: 'description', headerName: 'Description', width: 150 },
            { field: 'spend', headerName: 'Spend', width: 100 },
            
        ],
        []
    );

    // Optional custom toolbar with export functionality.
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // Conditionally render UI based on whether we have a valid date.
    if (!computedDate) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography color="error">No valid date provided.</Typography>
            </Box>
        );
    }

    return (
        <div>
            <Typography sx={{ p: '8px' }} variant="h6">
                Purchase Orders for Day
            </Typography>

            {/* Technical options: small links for debugging */}
            <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <Link
                    sx={{ color: 'gray' }}
                    component="button"
                    variant="caption"
                    onClick={() => setOpenDetailsQuery(true)}
                >
                    Details Query
                </Link>
            </Box>

            <Paper elevation={3} sx={{ height: 400, width: '100%', p: 2 }}>
                <Box sx={{ height: '100%' }}>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <Typography>Loading...</Typography>
                        </Box>
                    ) : error ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <Typography color="error">Error: {error.message}</Typography>
                        </Box>
                    ) : (
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            disableSelectionOnClick
                            autoHeight={false}
                            headerHeight={40}
                            sx={enterpriseGridOverrideTheme}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                    csvOptions: { fileName: generateExportFileName() },
                                    printOptions: { fileName: generateExportFileName() },
                                },
                            }}
                        />
                    )}
                </Box>

                {/* Pop-up dialogs */}
                <MembershipPopup open={openMembership} onClose={() => setOpenMembership(false)} />

                <SQLEditorPopup
                    open={openDetailsQuery}
                    onClose={() => setOpenDetailsQuery(false)}
                    title="Details Query"
                    endpoint={`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/queries/calendar/calendar-pos-details-query`}
                />
            </Paper>
        </div>
    );
};

export default PODayDetails;
