import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import config from '../../../config';

export const MembershipPopup = ({ open, onClose }) => {
  const url = `${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/members/get-purchasers`;
  const { data, isLoading, error } = useAuthenticatedData(url);

  // The endpoint returns the list of purchasers as a JSON array.
  const usersList = Array.isArray(data) ? data : [];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Who is Included?</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <div>{error.message}</div>
        ) : (
          <List dense sx={{ 
            '& .MuiListItem-root': {
              '&::before': {
                content: '"•"',
                color: '#ffffff',
                display: 'inline-block',
                width: '1em',
                marginLeft: '-1em'
              }
            }
          }}>
            {usersList.map((user, index) => (
              <ListItem key={index} sx={{ color: '#ffffff', pl: 4 }}>
                <ListItemText primary={user} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size="small" sx={{ color: '#ffffff' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MembershipPopup;