
import { gql, useQuery } from '@apollo/client';


export const GET_FEATURES = gql`
  query FeatureNames {
    featureNames
}
`;

export const GET_ALL_QUERIES = gql`
  query Queries {
    queries {
        id
        name
        sql
        description
        createdOn
        createdBy
        queryType
        featureName
        timePeriod
        subject
        department
        status
        version
        versionTag
        isValid
        notes
    }
}

`;

export const GET_QUERIES_BY_FEATURE = gql`
  query GetCustomers($search: String!) {
       customer(where: { name: { contains: $search } }, first: null) {
        pageInfo {
            hasNextPage
            endCursor
        }
        nodes {
            acctNo
            name
            assignedSalesperson
        }
    }
  }
`;

export const GET_PURCHASERS_QUERY = gql`

    query Salespeople {
        salespeople {      
            cCode       
            fullName         
        }
    }

`;


export const GET_PURCHASER_BY_CCODE_QUERY = gql`

query GetSalespersonByCcode($ccode: String!) {
  salespeople (where: { ccode: { eq: $ccode } }) {
       
      
          fullName
          cCode
      
  }
}
`;


export const GET_DEPARTMENT_CALENDAR_QUERY = gql`

    query DepartmentCalendarActivity {
    departmentCalendarActivity {
        ccode
        date
        purchaseRFQDocCount
        purchaseRFQLineCount
        purchaseRFQDollars
        repairRFQDocCount
        repairRFQLineCount
        repairRFQDollars
        totalRFQDocCount
        totalRFQLineCount
        totalRFQDollars
        poDocCount
        poLineCount
        poDollars
        sellPricesUpdated
        callTrackerDocCount
        callTrackerLineCount
    }
}


`;



