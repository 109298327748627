import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';

export const SQLEditorPopup = ({ open, onClose, title, endpoint }) => {
  const { data, isLoading, error } = useAuthenticatedData(endpoint);
  const queryText = data || '';

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <div>{error.message}</div>
        ) : (
          <TextField
            multiline
            fullWidth
            minRows={10}
            value={queryText}
            InputProps={{ readOnly: true }}
            variant="outlined"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size="small" sx={{ color: '#ffffff' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SQLEditorPopup;
