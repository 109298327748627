import React, { useMemo, useState } from 'react';
import { Box, Typography, CircularProgress, Alert, Paper, Tabs, Tab, Card, CardContent } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import config from '../../../config';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';

const SalesPerformanceScorecardBusinessGrowthQtyDetail = () => {
  const { ccode, mode } = useParams();
  const [activeTab, setActiveTab] = useState(mode);
  const navigate = useNavigate();

  const formatDate = (value) => {
    if (!value) return "";
    const date = new Date(value);
    if (isNaN(date)) return "";
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };


  // Compute the date range based on the active mode.
  const computeDateRange = (mode) => {
    const now = new Date();
    let start;
    switch (mode) {
      case 'mtd': // Month-to-Date: from the start of the month
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
      case 'qtd': { // Quarter-to-Date: from the start of the current quarter
        const quarter = Math.floor(now.getMonth() / 3);
        start = new Date(now.getFullYear(), quarter * 3, 1);
        break;
      }
      case 'ytd': // Year-to-Date: from January 1st
        start = new Date(now.getFullYear(), 0, 1);
        break;
      default:
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
    }
    return {
      from: encodeURIComponent(start.toISOString()),
      to: encodeURIComponent(now.toISOString()),
    };
  };

  // Memoize the date range so it only recalculates when activeTab changes.
  const { from, to } = useMemo(() => computeDateRange(activeTab), [activeTab]);

  // Build the API endpoint using the computed date range.
  const endpoint = `${config.apiBaseUrl}/sales/scorecard/${ccode}/newBusinessGrowthQtyDetails?from=${from}&to=${to}`;
  const { data: salesPersonName, isLoading: isLoadingName, error: errorName } = useAuthenticatedData(`${config.apiBaseUrl}/data/name?ccode=${ccode}`);

  const { data, isLoading, error } = useAuthenticatedData(endpoint);

  const { columns, rows } = useMemo(() => {
    if (!data) return { columns: [], rows: [] };

    const columns = [
      {
        field: 'accountName',
        headerName: 'Account',
        width: 300,

      },
      {
        field: 'accountNumber',
        headerName: 'Account Number',
        width: 180,
      },
      {
        field: 'eventDate',
        headerName: 'Date',
        width: 180,
        valueFormatter: (params) => formatDate(params),

      },
      {
        field: 'salesOrderDocNo',
        headerName: 'Pentagon SO doc no',
        width: 180,
      },
      {
        field: 'salesOrderDocTotal',
        headerName: 'Pentagon SO doc total',
        width: 180,
        valueFormatter: (params) =>
          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(params)
      },
    ];

    const rows = data
      .map((item) => ({
        id: item.id,
        accountName: item.accountName,
        accountNumber: item.accountNumber,
        colorCategory: item.colorCategory,
        salesOrderDocNo: item.salesOrderDocNo,
        salesOrderDocTotal: item.salesOrderDocTotal,
        eventDate: item.eventDate,
      }))
      .sort((a, b) => b.accountName.localeCompare(a.accountName));

    return { columns, rows };
  }, [data]);

  const handleTabChange = (event, newValue) => {
    navigate(`/sales/salesperson/${ccode}/scorecard/new-business-growth-qty/${newValue}`);
    setActiveTab(newValue);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <Paper elevation={3} sx={{ p: 2, maxWidth: '100%', mt: 4 }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',

      }}>


        <Typography variant="h1" component="h1" gutterBottom align="left">
          Growth Qty Detail - {salesPersonName}
        </Typography>

        <Card sx={{
          width: '50%',
          background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)', // Dark gradient
          border: '1px solid #424242',
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
          borderRadius: '12px',
          p: 0
        }}>
          <CardContent
            sx={{
              p: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: '#ffffff',
                fontWeight: 500,
                fontSize: '.9rem'
              }}
            >
              About the Data
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#b0b0b0',
                fontSize: '0.75rem' // Slightly smaller text
              }}
            >
              This shows all customers that were dead when {salesPersonName} sold to them, regardless of who the account might be assigned to.
              Currently you're seeing all the customers {salesPersonName} sold to {mode.toUpperCase()} that did not buy ANYTHING from us last year.
        

            </Typography>
          </CardContent>
        </Card>


      </Box>


      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        sx={{ mb: 2 }}
      >
        <Tab
          label="Month-to-Date"
          value="mtd"
          sx={{
            color: activeTab === 'mtd' ? '#ffffff !important' : '#b3b3b3',
            fontWeight: activeTab === 'mtd' ? 'bold' : 'normal',
          }}
        />
        <Tab
          label="Quarter-to-Date"
          value="qtd"
          sx={{
            color: activeTab === 'qtd' ? '#ffffff !important' : '#b3b3b3',
            fontWeight: activeTab === 'qtd' ? 'bold' : 'normal',
          }}
        />
        <Tab
          label="Year-to-Date"
          value="ytd"
          sx={{
            color: activeTab === 'ytd' ? '#ffffff !important' : '#b3b3b3',
            fontWeight: activeTab === 'ytd' ? 'bold' : 'normal',
          }}
        />
      </Tabs>

      <Box sx={{ height: 800, width: '100%' }}>
        <DataGrid
          density="compact"
          rows={rows}
          columns={columns}
          getRowClassName={(params) =>
            params.row ? `row-${params.row.colorCategory?.toLowerCase()}` : ''
          }
          sx={{
            ...enterpriseGridOverrideTheme,
            '& .MuiDataGrid-cell': {
              display: 'flex',
              alignItems: 'center',
              color: 'black',
              fontWeight: 700,
            },
            '& .MuiDataGrid-row.row-red': {
              backgroundColor: '#D88C8C !important',
              '& .MuiDataGrid-cell': {
                backgroundColor: '#D88C8C !important',
              },
            },
            '& .MuiDataGrid-row.row-green': {
              backgroundColor: '#73A57D !important',
              '& .MuiDataGrid-cell': {
                backgroundColor: '#73A57D !important',
              },
            },
            '& .MuiDataGrid-row.row-yellow': {
              backgroundColor: '#D1A752 !important',
              '& .MuiDataGrid-cell': {
                backgroundColor: '#D1A752 !important',
              },
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              '&.Mui-selected': {
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: { fileName: 'export' },
              printOptions: { fileName: 'export' },
            },
          }}
          hideFooter
        />
      </Box>
    </Paper>
  );
};

export default SalesPerformanceScorecardBusinessGrowthQtyDetail;
