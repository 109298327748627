import React from 'react';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import config from '../../../config';

const SalesPerformancePanel = ({ ccode }) => {
    const { data, isLoading, error } = useAuthenticatedData(`${config.apiBaseUrl}/sales/scorecard?ccode=${ccode}`);

    // Map the status from the API to actual colors for background and border.
    const statusColorMapping = {
        green: { background: '#2A7040', border: '#1E5A33' },
        yellow: { background: '#A07D32', border: '#7C6025' },
        red: { background: '#944848', border: '#7A3636' }
    };

    // Colors for key target boxes
    const keyTargetColors = {
        background: '#605d3d', // Darker background for key targets
        border: '#8C6B1F'      // Dark Bronze for key target borders
    };


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    };

    const formatPercent = (value) => {
        if (value === null || value === undefined) return 'N/A';
        if (isNaN(value)) return 'N/A';
        return `${Math.round(value)}%`;
    };

    const openRevenuePage = (mode) => {
        const url = `/sales/salesperson/${ccode}/scorecard/revenue/${mode}`;
        window.open(url, '_blank');
    };

    const openPricingPage = (mode) => {
        const url = `/sales/salesperson/${ccode}/scorecard/pricing/${mode}`;
        window.open(url, '_blank');
    };

    const openNewBusinessGrowthPacePage = (mode) => {
        const url = `/sales/salesperson/${ccode}/scorecard/new-business-growth-pace/${mode}`;
        window.open(url, '_blank');
    };

    const openNewBusinessGrowthQtyPage = (mode) => {
        const url = `/sales/salesperson/${ccode}/scorecard/new-business-growth-qty/${mode}`;
        window.open(url, '_blank');
    };

    const openRetentionDetailsPage = (mode) => {
        const url = `/sales/salesperson/${ccode}/scorecard/retention/${mode}`;
        window.open(url, '_blank');
    };

    // The MetricBox now expects a "status" prop which is the string returned by the API.
    const MetricBox = ({ title, value, format, status, onClick }) => {
        const isKeyTarget = title.includes("YTD % Key Targets");

const safeValue = value ?? 0;

        // Determine if data exists
        const hasData = safeValue !== null && !isNaN(safeValue);


        // Map the status to colors
     //   const { background, border } = statusColorMapping[status] || { background: '#4F4F4F', border: '#3B3B3B' };

      // For key targets, use special colors. Otherwise, use the server-supplied status mapping.
      const { background, border } = isKeyTarget
      ? keyTargetColors
      : (statusColorMapping[status] || { background: '#4F4F4F', border: '#3B3B3B' });


        const formattedValue =
            format === 'currency'
                ? formatCurrency(hasData ? safeValue : 0)
                : format === 'percent'
                    ? formatPercent(hasData ? safeValue : 0)
                    : hasData
                        ? format === 'number'
                            ? safeValue.toFixed(0)  // No decimal places for numbers
                            : safeValue.toFixed(1)
                        : 'N/A';
        return (
            <Box
                onClick={onClick}
                sx={{
                    bgcolor: background,
                    p: 0,
                    borderRadius: 1,
                    border: 2,
                    borderColor: border,
                    width: '150px',
                    textAlign: 'center',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    cursor: onClick ? 'pointer' : 'default',
                    '&:hover': onClick ? { boxShadow: '0 4px 8px rgba(0,0,0,0.2)' } : {}
                }}
            >
                <Typography variant="subtitle2" color="white" sx={{ mb: 0, fontWeight: 500, fontSize: '.75rem' }}>
                    {title}
                </Typography>
                <Typography variant="h6" color="white" sx={{ fontWeight: 600, fontSize: '.75rem' }}>
                    {formattedValue}
                </Typography>
            </Box>
        );
    };

    if (isLoading)
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                <CircularProgress />
            </Box>
        );
    if (error)
        return (
            <Alert severity="error">
                Error loading sales performance data: {error.message}
            </Alert>
        );
    if (!data)
        return <Alert severity="info">No sales performance data available</Alert>;

    // Build metrics using the server-supplied values and colors.
    const metrics = [
        {
            title: 'Revenue',
            goal: data.annualRevenueGoal,
            boxes: [
                {
                    title: 'Month to Date',
                    value: data.revenueMonthToDate,
                    status: data.revenueMonthToDateColor,
                    format: 'currency',
                    target: 416666,
                    mode: 'mtd'
                },
                {
                    title: 'Quarter to Date',
                    value: data.qtrToDate,
                    status: data.qtrToDateColor,
                    format: 'currency',
                    target: 1250000,
                    mode: 'qtd'
                },
                {
                    title: 'Year to Date',
                    value: data.yearToDate,
                    status: data.yearToDateColor,
                    format: 'currency',
                    target: 5000000,
                    mode: 'ytd'
                }
            ]
        },
        {
            title: 'New Business Growth',
            paceGoal: data.newBusinessGrowthPaceAnnualGoal,
            qtyGoal: data.newBusinessGrowthQtyAnnualGoal,
            paceBoxes: [
                {
                    title: 'MTD Pace',
                    value: data.newBusinessGrowthPaceMonthToDate,
                    status: data.newBusinessGrowthPaceMonthToDateColor,
                    format: 'percent',
                    target: 100,
                    mode: 'mtd'
                },
                {
                    title: 'QTD Pace',
                    value: data.newBusinessGrowthPaceQtrToDate,
                    status: data.newBusinessGrowthPaceQtrToDateColor,
                    format: 'percent',
                    target: 100,
                    mode: 'qtd'
                },
                {
                    title: 'YTD Pace',
                    value: data.newBusinessGrowthPaceYearToDate,
                    status: data.newBusinessGrowthPaceYearToDateColor,
                    format: 'percent',
                    target: 100,
                    mode: 'ytd'
                },
                {
                    title: 'YTD % Key Targets',
                    value: data.newBusinessGrowthYTDPercentKeyTargets,
                    status: data.newBusinessGrowthYTDPercentKeyTargetsColor,
                    format: 'percent',
                    target: 50
                }
            ],
            qtyBoxes: [
                {
                    title: 'MTD Qty',
                    value: data.newBusinessGrowthQtyMonthToDate,
                    status: data.newBusinessGrowthQtyMonthToDateColor,
                    format: 'number',
                    target: 2,
                    mode: 'mtd'
                },
                {
                    title: 'QTD Qty',
                    value: data.newBusinessGrowthQtyQtrToDate,
                    status: data.newBusinessGrowthQtyQtrToDateColor,
                    format: 'number',
                    target: 4,
                    mode: 'qtd'
                },
                {
                    title: 'YTD Qty',
                    value: data.newBusinessGrowthQtyYearToDate,
                    status: data.newBusinessGrowthQtyYearToDateColor,
                    format: 'number',
                    target: 16,
                    mode: 'ytd'
                }
            ]
        },
        {
            title: 'Retention',
            goal: data.retentionAnnualGoal,
            boxes: [
                {
                    title: '3M',
                    value: data.retentionCustomersPurchasedMTD,
                    status: data.retentionCustomersPurchasedMTDColor,
                    format: 'percent',
                    target: 65,
                    mode: '3m'
                },
                {
                    title: '12M',
                    value: data.retentionCustomersPurchasedYTD,
                    status: data.retentionCustomersPurchasedYTDColor,
                    format: 'percent',
                    target: 65,
                    mode: '12m'
                },
                {
                    title: 'YTD % Key Targets',
                    value: data.retentionCustomersPurchasedYTDKeyTargets,
                    status: data.retentionCustomersPurchasedYTDKeyTargetsColor,
                    format: 'percent',
                    target: 50
                }
            ]
        },
        {
            title: 'Pricing',
            goal: data.pricingAnnualGoal,
            boxes: [
                {
                    title: 'MTD',
                    value: data.pricingPaceMonthToDate,
                    status: data.pricingPaceMonthToDateColor,
                    format: 'percent',
                    target: -1,
                    mode: 'mtd'
                },
                {
                    title: 'QTD',
                    value: data.pricingPaceQtrToDate,
                    status: data.pricingPaceQtrToDateColor,
                    format: 'percent',
                    target: -1,
                    mode: 'qtd'
                },
                {
                    title: 'YTD',
                    value: data.pricingPaceYearToDate,
                    status: data.pricingPaceYearToDateColor,
                    format: 'percent',
                    target: -1,
                    mode: 'ytd'
                }
            ]
        }
    ];

    return (
        <Box sx={{ bgcolor: '#121212', p: 0, borderRadius: 2, width: '100%' }}>
            {metrics.map((metric) => (
                <Box key={metric.title} sx={{ mb: 1 }}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '260px 1fr',
                            alignItems: 'start',
                            gap: 1
                        }}
                    >
                        <Box>
                            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500, fontSize: '1rem' }}>
                                {metric.title}
                            </Typography>
                            {metric.title === 'New Business Growth' ? (
                                <>
                                    <Typography
                                        variant="subtitle2"
                                        color="lightgray"
                                        sx={{ display: 'block', fontWeight: 400, fontSize: '.8rem' }}
                                    >
                                        Annual Pace Goal: {metric.paceGoal}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color="lightgray"
                                        sx={{ display: 'block', fontWeight: 400, fontSize: '.8rem' }}
                                    >
                                        Annual QTY Goal: {metric.qtyGoal}
                                    </Typography>
                                </>
                            ) : (
                                <Typography
                                    variant="subtitle2"
                                    color="lightgray"
                                    sx={{ display: 'block', fontWeight: 400, fontSize: '.8rem' }}
                                >
                                    Annual Goal: {metric.goal}
                                </Typography>
                            )}
                        </Box>

                        {metric.title === 'New Business Growth' ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, ml: 4 }}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, maxWidth: '800px' }}>
                                    {metric.paceBoxes.map((box, index) => (
                                        <MetricBox
                                            key={`pace-${index}`}
                                            title={box.title}
                                            value={box.value}
                                            status={box.status}
                                            format={box.format}
                                            onClick={box.mode ? () => openNewBusinessGrowthPacePage(box.mode) : null}
                                        />
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, maxWidth: '800px' }}>
                                    {metric.qtyBoxes.map((box, index) => (
                                        <MetricBox
                                            key={`qty-${index}`}
                                            title={box.title}
                                            value={box.value}
                                            status={box.status}
                                            format={box.format}
                                            onClick={box.mode ? () => openNewBusinessGrowthQtyPage(box.mode) : null}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, maxWidth: '800px', ml: 4 }}>
                                {metric.boxes.map((box, index) => (
                                    <MetricBox
                                        key={index}
                                        title={box.title}
                                        value={box.value}
                                        status={box.status}
                                        format={box.format}
                                        onClick={
                                            box.mode
                                                ? metric.title === 'Pricing'
                                                    ? () => openPricingPage(box.mode)
                                                    : metric.title === 'Retention'
                                                        ? () => openRetentionDetailsPage(box.mode)
                                                        : () => openRevenuePage(box.mode)
                                                : null
                                        }
                                    />
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default SalesPerformancePanel;
