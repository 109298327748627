import React, { useMemo, useState } from 'react';
import {
  Typography,
  Paper,
  Box,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Card,
  CardContent,
  Link,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../../../../hooks/useAuthenticatedData';
import config from '../../../../config';
import { enterpriseGridOverrideTheme } from '../../../../themes/EnterpriseGridTheme';
import MembershipPopup from '../MembershipPopup';
import SQLEditorPopup from '../SQLEditorPopup';

// Simplified Legend component with more concise descriptions
const ColorLegend = () => (
  <Card
    sx={{
      background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
      border: '1px solid #424242',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
      borderRadius: '12px',
      p: 1,
      width: '100%',
    }}
  >
    <Typography
      variant="h6"
      sx={{
        color: '#ffffff',
        fontWeight: 500,
        fontSize: '.9rem',
        mb: 0.5,
      }}
    >
      Inventory Health Status
    </Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      {[
        { color: '#2A7040', label: 'Green: 15% or more ahead of goal' },
        { color: '#A07D32', label: 'Yellow: 1-14% ahead of goal' },
        { color: '#944848', label: 'Red: Behind goal' },
      ].map(({ color, label }) => (
        <Box
          key={label}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            py: 0.25,
          }}
        >
          <Box
            sx={{
              width: '16px',
              height: '16px',
              backgroundColor: color,
              borderRadius: '2px',
              flexShrink: 0,
            }}
          />
          <Typography
            sx={{
              color: '#b0b0b0',
              fontSize: '0.7rem',
              lineHeight: 1.2,
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              minHeight: '16px',
            }}
          >
            {label}
          </Typography>
        </Box>
      ))}
    </Box>
  </Card>
);

// This function determines the color status for inventory health categories
// Updated to match the simplified legend logic
const getInventoryHealthStatus = (value, ageBucket) => {
  
  // Goals for each age bucket
  const goals = {
    '0-90': 56,   // At least 56%
    '91-180': 26, // At most 26%
    '181-365': 16, // At most 16%
    '366+': 2     // At most 2%
  };

  const goal = goals[ageBucket];
  
  // For 0-90 days, higher percentage is better (want minimum 56%)
  if (ageBucket === '0-90') {
    // 15% or more ahead of goal (≥ 64.4%)
    if (value >= goal * 1.15) return 'green';
    // 1-14% ahead of goal (56-64.3%)
    if (value >= goal) return 'yellow';
    // Behind goal (<56%)
    return 'red';
  } 
  // For all other buckets, lower percentage is better (want maximum %)
  else {
    // 15% or more ahead of goal (≤ 85% of maximum)
    if (value <= goal * 0.85) return 'green';
    // 1-14% ahead of goal (85-99% of maximum)
    if (value <= goal) return 'yellow';
    // Behind goal (>100% of maximum)
    return 'red';
  }
};

function PurchasingDepartmentInventoryHealthDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  // State for technical pop-ups
  const [openMembership, setOpenMembership] = useState(false);
  const [openSummaryQuery, setOpenSummaryQuery] = useState(false);
  const [openDetailsQuery, setOpenDetailsQuery] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const period = searchParams.get('period')?.toUpperCase() || 'MTD';

  const url = `${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/inventory-health-details`;
  const { data, isLoading, error } = useAuthenticatedData(url);

  // Process the data into rows for the DataGrid.
  const rows = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];
    return data.map((item, index) => ({
      id: index, // Ensure each row has a unique id
      ...item,
    }));
  }, [data]);

  // Generate export filename.
  const generateExportFileName = () => `Purchasing-inventory-health-details`;

  // Define columns for the DataGrid.
  const columns = useMemo(
    () => [
      { field: 'ageCategory', headerName: 'Age Category', width: 120 },
      {
        field: 'totalCostDollars',
        headerName: 'Total Cost ($)',
        width: 150,
        type: 'number',
        valueFormatter: (params) =>
          params != null ? `$${Number(params).toLocaleString()}` : '',
      },
      {
        field: 'percentOfTotalCost',
        headerName: '% of Total Cost',
        width: 150,
        type: 'number',
        valueFormatter: (params) =>
          params != null ? `${Number(params).toFixed(2)}%` : '',
      },
      {
        field: 'totalSaleValueDollars',
        headerName: 'Total Sale Value ($)',
        width: 170,
        type: 'number',
        valueFormatter: (params) =>
          params != null ? `$${Number(params).toLocaleString()}` : '',
      },
      {
        field: 'expectedGMDollars',
        headerName: 'Expected GM ($)',
        width: 150,
        type: 'number',
        valueFormatter: (params) =>
          params != null ? `$${Number(params).toLocaleString()}` : '',
      },
      {
        field: 'expectedGMPercent',
        headerName: 'Expected GM (%)',
        width: 150,
        type: 'number',
        valueFormatter: (params) =>
          params != null ? `${Number(params).toFixed(2)}%` : '',
      },
    ],
    []
  );

  // Dummy function for period tabs (if needed).
  const handleTabChange = (event, newPeriod) => {
    navigate({
      pathname: location.pathname,
      search: `?period=${newPeriod}`,
    });
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
      <Typography variant="h1" component="h1" gutterBottom align="left">
        Purchasing Scorecard - Inventory Health Details
      </Typography>

      {/* Technical options: small links for debugging */}
      <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <Link
          sx={{ color: 'gray' }}
          component="button"
          variant="caption"
          onClick={() => setOpenMembership(true)}
        >
          Who is Included?
        </Link>
        <Link
          sx={{ color: 'gray' }}
          component="button"
          variant="caption"
          onClick={() => setOpenSummaryQuery(true)}
        >
          Summary Query
        </Link>
        <Link
          sx={{ color: 'gray' }}
          component="button"
          variant="caption"
          onClick={() => setOpenDetailsQuery(true)}
        >
          Details Query
        </Link>
      </Box>

      {/* About the Data and Legend Block */}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          mb: 2,
        }}
      >
        {/* About this Data card */}
        <Box sx={{ flex: 1, minWidth: 300 }}>
          <Card
            sx={{
              background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
              border: '1px solid #424242',
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
              borderRadius: '12px',
              height: '100%',
            }}
          >
            <CardContent sx={{ p: 1 }}>
              <Typography
                variant="h6"
                sx={{
                  color: '#ffffff',
                  fontWeight: 500,
                  fontSize: '.9rem',
                  mb: 0.5,
                }}
              >
                About the Data
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: '#b0b0b0',
                  fontSize: '0.75rem',
                }}
              >
                This shows the age of purchased material, the cost to purchase it, and the potential value for its sale if it were to sell
                at current Pentagon sell prices. This is not our entire inventory, but rather the inventory we have on shelf that was purchased on a PO for stock by one of the 
                people on the purchasing team, which can be seen by clicking the "Who is included?" link above.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        {/* Legend */}
        <Box sx={{ flex: 1, minWidth: 300 }}>
          <ColorLegend />
        </Box>
      </Box>

      

      <Paper elevation={3} sx={{ height: 400, width: '100%', p: 2 }}>
        <Box sx={{ height: '100%' }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
              <Typography sx={{ ml: 2 }}>Loading...</Typography>
            </Box>
          ) : error ? (
            <Alert severity="error">Error: {error.message}</Alert>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 25]}
              disableSelectionOnClick
              autoHeight={false}
              headerHeight={40}
              sx={{
                ...enterpriseGridOverrideTheme,
                '& .MuiDataGrid-row.green': {
                  backgroundColor: '#2A7040 !important',
                  color: 'white !important',
                  '&:hover': {
                    backgroundColor: '#2A7040 !important',
                  },
                  '& .MuiDataGrid-cell': {
                    backgroundColor: '#2A7040 !important',
                    color: 'white !important',
                  },
                },
                '& .MuiDataGrid-row.yellow': {
                  backgroundColor: '#A07D32 !important',
                  color: 'white !important',
                  '&:hover': {
                    backgroundColor: '#A07D32 !important',
                  },
                  '& .MuiDataGrid-cell': {
                    backgroundColor: '#A07D32 !important',
                    color: 'white !important',
                  },
                },
                '& .MuiDataGrid-row.red': {
                  backgroundColor: '#944848 !important',
                  color: 'white !important',
                  '&:hover': {
                    backgroundColor: '#944848 !important',
                  },
                  '& .MuiDataGrid-cell': {
                    backgroundColor: '#944848 !important',
                    color: 'white !important',
                  },
                },
              }}
              getRowClassName={(params) => {
                const ageCategory = params.row.ageCategory;
                const percentOfTotalCost = params.row.percentOfTotalCost;
                
                // Determine which category the row falls into
                let category = '';
                if (ageCategory?.includes('0-90')) {
                  category = '0-90';
                } else if (ageCategory?.includes('91-180')) {
                  category = '91-180';
                } else if (ageCategory?.includes('181-365')) {
                  category = '181-365';
                } else if (ageCategory?.includes('366+')) {
                  category = '366+';
                }
                
                // Apply the color logic
                return getInventoryHealthStatus(percentOfTotalCost, category);
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  csvOptions: { fileName: generateExportFileName() },
                  printOptions: { fileName: generateExportFileName() },
                },
              }}
            />
          )}
        </Box>
      </Paper>

      {/* Pop-up dialogs */}
      <MembershipPopup open={openMembership} onClose={() => setOpenMembership(false)} />
      <SQLEditorPopup
        open={openSummaryQuery}
        onClose={() => setOpenSummaryQuery(false)}
        title="Summary Query"
        endpoint={`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/queries/inventory-health-summary-query`}
      />
      <SQLEditorPopup
        open={openDetailsQuery}
        onClose={() => setOpenDetailsQuery(false)}
        title="Details Query"
        endpoint={`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/queries/inventory-health-details-query`}
      />
    </Paper>
  );
}

export default PurchasingDepartmentInventoryHealthDetails;