import React, { useMemo } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Alert,
    Paper,
    Card,
    CardContent,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import config from '../../../config';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';



// Updated getColor with the colors from your example.
const getColor = (color) => {
    const mapping = {
        green: '#73A57D',
        yellow: '#D1A752',
        red: '#D6937E',
    };
    return color ? mapping[color.toLowerCase()] || 'inherit' : 'inherit';
};

const openPage = (url) => {
    window.open(url, '_blank');
};

const ColorLegend = () => (
    <Card
        sx={{
            background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
            border: '1px solid #424242',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
            borderRadius: '12px',
            p: 1,
            width: '100%',
        }}
    >
        <Typography
            variant="h6"
            sx={{
                color: '#ffffff',
                fontWeight: 500,
                fontSize: '.9rem',
                mb: 0.5,
            }}
        >
            Legend
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            {[
                { color: '#73A57D', label: 'Boiling - Sales each week for the past 90 days (13 weeks)' },
                { color: '#D1A752', label: 'Warm - Sales in the last month or in both the two previous months' },
                { color: '#D6937E', label: 'Cold - Sales in the past year' },
            ].map(({ color, label }) => (
                <Box
                    key={label}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        py: 0.25,
                    }}
                >
                    <Box
                        sx={{
                            width: '16px',
                            height: '16px',
                            backgroundColor: color,
                            borderRadius: '2px',
                            flexShrink: 0,
                        }}
                    />
                    <Typography
                        sx={{
                            color: '#b0b0b0',
                            fontSize: '0.7rem',
                            lineHeight: 1.2,
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: '16px',
                        }}
                    >
                        {label}
                    </Typography>
                </Box>
            ))}
        </Box>
    </Card>
);

const SalesDepartmentScorecardPricingDetails = () => {
    const navigate = useNavigate();
    // Fetch revenue details from your API endpoint.
    // Expected data format: an array of objects with:
    // { id, name, ccode, forDate, mtd, mtdColor, qtd, qtdColor, ytd, ytdColor }
    const { data, isLoading, error } = useAuthenticatedData(
        `${config.apiBaseUrl}/sales/department/scorecard/pricing-details`
    );

    // Process data into rows and define columns for the DataGrid.
    const { rows, columns } = useMemo(() => {
        if (!data) return { rows: [], columns: [] };

        // Map each record to a row.
        const rows = data.map((record, index) => ({
            // Use ccode or index as unique identifier.
            id: record.ccode || index,
            ...record,
        }));

        // Define the columns for Salesperson, MTD, QTD, YTD.
        const columns = [
            {
                field: 'name',
                headerName: 'Salesperson',
                width: 250,
            },
            {
                field: 'mtd',
                headerName: 'MTD',
                width: 150,
                renderCell: (params) => {
                    const cellValue = params.row.mtd;
                    return (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: params.row.mtdColor ? getColor(params.row.mtdColor) : 'inherit',
                            }}
                        >
                            {cellValue != null
                                ? `${cellValue.toFixed(2)}%`
                                : ''}
                        </Box>
                    );
                },
            },
            {
                field: 'qtd',
                headerName: 'QTD',
                width: 150,
                renderCell: (params) => {
                    const cellValue = params.row.qtd;
                    return (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: params.row.qtdColor ? getColor(params.row.qtdColor) : 'inherit',
                            }}
                        >
                   {cellValue != null
                                ? `${cellValue.toFixed(2)}%`
                                : ''}
                        </Box>
                    );
                },
            },
            {
                field: 'ytd',
                headerName: 'YTD',
                width: 150,
                renderCell: (params) => {
                    const cellValue = params.row.ytd;
                    return (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: params.row.ytdColor ? getColor(params.row.ytdColor) : 'inherit',
                            }}
                        >
                            {cellValue != null
                                ? `${cellValue.toFixed(2)}%`
                                : ''}
                        </Box>
                    );
                },
            },
        ];

        return { rows, columns };
    }, [data]);

    if (isLoading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error.message}</Alert>;

    return (
        <Paper elevation={3} sx={{ p: 2, maxWidth: '100%', mt: 2 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mb: 2,
                }}
            >
                {/* Title updated to yellow */}
                <Typography variant="h1" component="h1" gutterBottom align="left">
                    Department Pricing Scorecard
                </Typography>
                {/* New flex container for side-by-side layout */}
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    <Box sx={{ flex: 1, minWidth: 300 }}>
                        <Card
                            sx={{
                                background: 'linear-gradient(135deg, #1e1e1e, #2d2d2d)',
                                border: '1px solid #424242',
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
                                borderRadius: '12px',
                                height: '100%',
                            }}
                        >
                            <CardContent sx={{ p: 1 }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: '#ffffff',
                                        fontWeight: 500,
                                        fontSize: '.9rem',
                                    }}
                                >
                                    About the Data
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: '#b0b0b0',
                                        fontSize: '0.75rem',
                                    }}
                                >
                                    These are the revenue details per salesperson. Each cell is colored based on performance
                                    indicators.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: 300 }}>
                        <ColorLegend />
                    </Box>
                </Box>
            </Box>

            <Box sx={{ height: 800, width: '100%' }}>
                <DataGrid
                    density="compact"
                    rows={rows}
                    columns={columns}
                    // On cell click navigate to the appropriate URL if cell is one of MTD, QTD, or YTD.
                    onCellClick={(params) => {
                        if (['mtd', 'qtd', 'ytd'].includes(params.field)) {
                            openPage(`/sales/salesperson/${params.row.ccode}/scorecard/pricing/${params.field}`);
                
                        }
                    }}
                    sx={{
                        ...enterpriseGridOverrideTheme,
                        // Overriding to a white background with black text.
                        '& .MuiDataGrid-cell': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 700,
                            backgroundColor: 'white',
                            color: 'black',
                        },
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer',
                        },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            csvOptions: { fileName: 'export' },
                            printOptions: { fileName: 'export' },
                        },
                    }}
                />
            </Box>
        </Paper>
    );
};

export default SalesDepartmentScorecardPricingDetails;
