// CallTrackersDayDetails.js
import React, { useMemo, useState, useEffect } from 'react';
import { Typography, Paper, Box, TextField, Link } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useAuthenticatedData from '../../../../../hooks/useAuthenticatedData';
import config from '../../../../../config';
import { enterpriseGridOverrideTheme } from '../../../../../themes/EnterpriseGridTheme';
import { useSearchParams } from 'react-router-dom';
import SQLEditorPopup from '../../SQLEditorPopup.js';

const CallTrackerDetails = ({ date: propDate }) => {
    const [searchParams] = useSearchParams();
    const [selectedCall, setSelectedCall] = useState(null);
    const [openDetailsQuery, setOpenDetailsQuery] = useState(false);

    // Compute the effective date using useMemo
    const computedDate = useMemo(() => {
        if (propDate) return propDate;
        const dateString = searchParams.get('date');
        return dateString ? new Date(dateString) : null;
    }, [propDate, searchParams]);

    // Format date for the API request
    const formattedDate = computedDate
        ? `${computedDate.getMonth() + 1}/${computedDate.getDate()}/${computedDate.getFullYear()}`
        : '';

    // Build the endpoint URL
    const endpoint = formattedDate
        ? `${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/get-call-trackers-for-day?date=${formattedDate}`
        : '';

    // Fetch data using the custom hook
    const { data, isLoading, error } = useAuthenticatedData(endpoint);

    // Prepare rows for DataGrid
    const rows = useMemo(() => {
        if (!data) return [];
        return data.map((item, index) => ({
            id: item.id || index,
            ...item,
        }));
    }, [data]);

    // Set the first call as selected when data loads
    useEffect(() => {
        if (rows.length > 0 && !selectedCall) {
            setSelectedCall(rows[0]);
        }
    }, [rows, selectedCall]);

    // Generate export filename
    const generateExportFileName = () => {
        if (!formattedDate) return 'call-trackers-no-date';
        const dateParts = formattedDate.split('/');
        return `call-trackers-${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
    };

    // Define columns
    const columns = useMemo(
        () => [
            { field: 'docNo', headerName: 'Pentagon Doc#', width: 120 },
            { field: 'fullName', headerName: 'User', width: 150 },
            { field: 'accountName', headerName: 'Account', width: 150 },
            {
                field: 'date',
                headerName: 'Date',
                width: 120,
                valueFormatter: (params) => {
                    if (!params.value) return '';
                    try {
                        const date = new Date(params.value);
                        return date.toLocaleDateString();
                    } catch (e) {
                        return params.value;
                    }
                }
            },
        ],
        []
    );

    // If no valid date is provided, show an error message
    if (!computedDate) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography color="error">No valid date provided.</Typography>
            </Box>
        );
    }

    return (
        <div>
            <Typography sx={{ p: '8px' }} variant="h6">
                Call Trackers for Day
            </Typography>

            {/* Technical options for debugging */}
            <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <Link
                    sx={{ color: 'gray' }}
                    component="button"
                    variant="caption"
                    onClick={() => setOpenDetailsQuery(true)}
                >
                    Details Query
                </Link>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
                {/* Master Grid Section */}
                <Paper elevation={3} sx={{ flex: 1, height: 400, p: 2 }}>
                    <Box sx={{ height: '100%' }}>
                        {isLoading ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <Typography>Loading...</Typography>
                            </Box>
                        ) : error ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <Typography color="error">Error: {error.message}</Typography>
                            </Box>
                        ) : (
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                disableSelectionOnClick={false}
                                onRowClick={(params) => setSelectedCall(params.row)}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 5, page: 0 },
                                    },
                                    selection: {
                                        selectedRowIds: rows.length > 0 ? [rows[0].id] : []
                                    }
                                }}
                                autoHeight={false}
                                headerHeight={40}
                                sx={{
                                    ...enterpriseGridOverrideTheme,
                                    '& .MuiDataGrid-row': {
                                        cursor: 'pointer',
                                        '&.Mui-selected': {
                                            backgroundColor: 'primary.main',
                                            '&:hover': {
                                                backgroundColor: 'primary.dark',
                                            },
                                        },
                                    },
                                }}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                        csvOptions: { fileName: generateExportFileName() },
                                        printOptions: { fileName: generateExportFileName() }
                                    }
                                }}
                            />
                        )}
                    </Box>
                </Paper>

                {/* Detail Section - Message Display */}
                <Paper elevation={3} sx={{ flex: 1, height: 500, p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Message
                    </Typography>
                    <TextField
                        multiline
                        fullWidth
                        minRows={10}
                        maxRows={20}
                        value={selectedCall?.message || 'Select a call to view details'}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{
                            height: 'calc(100% - 40px)',
                            border: '1px solid #e0e0e0',
                            '& .MuiOutlinedInput-root': {
                                height: '100%',
                                '& textarea': {
                                    height: '100% !important',
                                },
                            },
                        }}
                    />
                </Paper>
            </Box>

            {/* SQL Query Popup for debugging */}
            <SQLEditorPopup
                open={openDetailsQuery}
                onClose={() => setOpenDetailsQuery(false)}
                title="Call Trackers Query"
                endpoint={`${config.apiBaseUrl}/supply-chain/purchasing/department/scorecard/queries/calendar/calendar-call-trackers-details-query`}
            />
        </div>
    );
};

export default CallTrackerDetails;